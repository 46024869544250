import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { CompaniesService } from '../../Services/CompaniesService/companies.service'
import { Company } from '../../Models/Company'
import { Invoice } from '../../Models/Invoice'
import { BsModalRef } from 'ngx-bootstrap'
import { Subject } from 'rxjs'
import { APIStatus } from '../../Enums/APIStatus'
import { InvoicesType } from "../../Enums/invoices-type.enum"
import { SortCode } from "../../Models/SortCode"
import calculations from "../../helpers/calculations"

@Component({
  selector: 'app-sort-invoices',
  templateUrl: './sort-invoices.component.html',
  styleUrls: ['./sort-invoices.component.css'],
})
export class SortInvoicesComponent implements OnInit {

  @Input() parentSubject: Subject<any>
  @Output() refresh = new EventEmitter<boolean>()

  modalRef: BsModalRef
  selectedCompany: Company
  selectedId: string = "0"
  selectedYear: number
  newInvoices: [Invoice]
  deletedInvoices: [Invoice]
  approvedInvoices: [Invoice]
  classifications: [SortCode]
  loading: boolean
  globalVat: number
  InvoicesType = InvoicesType

  constructor(private companiesService: CompaniesService) {
  }


  ngOnInit() {
    // Listen for changes in the dashboard component
    this.parentSubject.subscribe(async json => {
      if (json.company) {
        this.selectedCompany = json.company
        this.selectedId = json.company.id
      }
      if (json.year) {
        this.selectedYear = json.year
      }
      if (json.globalVat) {
        this.globalVat = json.globalVat
      }

      // When the dashboard has pushed a changed,
      // either a new company has been selected,
      // or the globalVat has been loaded,
      // or the refresh button has been pressed
      await this.companySelected()
    })
  }

  /**
   * When this is called, it will get the updated invoices for the selected company.
   */
  async refreshData() {
    this.refresh.emit(true)
    await this.companySelected()
  }

  /**
   * Convert a MySql datetime string to JS Date object
   *
   * @param invoices
   */
  convertMysqlDateToJsDate(invoices): [Invoice] {
    return invoices.map(invoice => {

      invoice.invoiceDate = new Date(Date.parse(invoice.invoiceDate))
      invoice.uploadDate = new Date(Date.parse(invoice.uploadDate))
      invoice.demandDate = new Date(Date.parse(invoice.demandDate))

      return invoice
    })
  }

  /**
   * Fetch the selected company's invoices.
   */
  async companySelected() {
    // Get invoices only when a company has been selected
    if (this.selectedCompany) {
      this.loading = true

      // Get company's classification
      let res = await this.companiesService.getCompanyClassifications(this.selectedCompany.id)
      if (!res || !res['classifications']) return
      this.classifications = res['classifications']

      // Get company's invoices
      res = await this.companiesService.getCompanyInvoicesList(this.selectedCompany.id, this.selectedYear)
      if (!res || !res['success']) return
      this.newInvoices = this.convertMysqlDateToJsDate(res['newInvoices'])
      this.approvedInvoices = this.convertMysqlDateToJsDate(res['approvedInvoices'])
      this.deletedInvoices = this.convertMysqlDateToJsDate(res['deletedInvoices'])

      // Calculate invoices' debit
      for (let inv of this.newInvoices) {
        inv.comment = inv.comment || '-'
        inv.deleteStatus = inv.approveStatus = APIStatus.None
        this.getClassificationName(inv)
        this.calculateDebits(inv)
      }
      for (let inv of this.approvedInvoices) {
        inv.comment = inv.comment || '-'
        inv.deleteStatus = inv.approveStatus = APIStatus.None
        this.getClassificationName(inv)
        this.calculateDebits(inv)
      }
      for (let inv of this.deletedInvoices) {
        inv.comment = inv.comment || '-'
        inv.deleteStatus = inv.approveStatus = APIStatus.None
        this.getClassificationName(inv)
        this.calculateDebits(inv)
      }

      this.loading = false
    }
  }

  /**
   * Calculate the given invoice's debits.
   * debitOne is the price - the vat
   * debitTwo is the vat
   *
   * @param invoice
   */
  calculateDebits(invoice) {
    calculations.calculateInvoiceDebits(invoice, this.globalVat)
  }

  getClassificationName(invoice: Invoice) {
    if (!this.classifications) return
    let i = 0
    for (let cl of this.classifications) {
      if (cl.accountKey == <any>invoice.classification) {
        invoice.localClassificationId = i
        return cl.accountName
      }
      i++
    }
    return "Unknown"
  }

}
