import { SortCodeType } from "../Enums/sort-code-type"

export class SortCode {
  id?: number
  sortCode: string
  sortName: string
  accountName: string
  accountKey: string
  vatPercent: number
  type: SortCodeType = SortCodeType.Regular

  constructor() {

  }
}
