import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SortCode } from '../../Models/SortCode';
import { Company } from '../../Models/Company';

import fileDownload from 'js-file-download';

@Injectable()
export class CompaniesService {

  constructor(private http: HttpClient, private config: ConfigService) {
  }

  public countCompanies() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.get(this.config.countCompaniesEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getCompaniesList() {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.get(this.config.companiesEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getCompanyInvoicesList(companyId: string, year?) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    if (!year) year = ''
    let url = this.config.invoicesEndPoint + '/' + companyId + '/' + year

    return new Promise((resolve) => {
      this.http.get(url, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getCompanyClassifications(companyId: string) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.get(this.config.getCompanyClassificationsEndPoint + '/' + companyId, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public createCompany(body: Company) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.post(this.config.companiesEndPoint, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public insertSortCodesBulk(companyId, sortCodes: Array<SortCode>) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    let body = {
      companyId,
      sortCodes,
    }
    return new Promise((resolve) => {
      this.http.post(this.config.InsertSortCodesBulkEndPoint, body, options).subscribe(res => {
        resolve(res);
      })
    })
  }

  public deleteSortCode(companyId, accountKey) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.delete(this.config.sortCodesEndPoint + '/' + companyId + '/' + accountKey, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public insertSortCodesCsv(companyId, file: File) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    let body = new FormData()
    body.append('file', file)
    body.append('companyId', companyId)
    return new Promise((resolve) => {
      this.http.post(this.config.UploadCsvSortCodesEndPoint, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public updateSortCode(companyId, sortCode, oldAccountKey) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    let body = {
      sortCode,
    }
    return new Promise((resolve) => {
      this.http.put(this.config.sortCodesEndPoint + '/' + companyId + '/' + oldAccountKey, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public updateCompanyDetails(companyId, body: Company) {
    const options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.put(this.config.companiesEndPoint + '/' + companyId, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public deleteCompany(companyId) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    return new Promise((resolve) => {
      this.http.delete(this.config.companiesEndPoint + '/' + companyId, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public async downloadAttach(url) {
    let headers = {
      "access-token": localStorage.getItem(this.config.TokenLocalStorageName),
    }
    const res = await fetch(url, {
      method: 'GET',
      headers,
    })
    const urlSplit = url.split('/')
    fileDownload(await res.blob(), urlSplit[urlSplit.length - 1])

    // return new Promise((resolve) => {
    //   this.http.get(url, options).subscribe((res: any) => {
    //     const urlSplit = url.split('/')
    //     console.log(res)
    //     // fileDownload(res, urlSplit[urlSplit.length - 1])
    //     resolve('done')
    //   })
    // })
  }

}
