import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from "@angular/router"
import { ConfigService } from "../../../Services/config/config.service"
import { BsModalRef, BsModalService } from "ngx-bootstrap"

@Component({
  selector: 'app-superadmin-dashboard',
  templateUrl: './superadmin-dashboard.component.html',
  styleUrls: ['./superadmin-dashboard.component.css']
})
export class SuperadminDashboardComponent implements OnInit {

  selectedIndex: number = 1
  isCollapsed = true
  modalRef: BsModalRef

  constructor(private config: ConfigService, private router: Router, private modalService: BsModalService) { }

  ngOnInit() {
  }

  disconnect() {
    this.modalRef.hide()
    localStorage.removeItem(this.config.UserNameLocalStorageName)
    localStorage.removeItem(this.config.TokenLocalStorageName)
    localStorage.removeItem(this.config.isMainAdminLocalStorageName)
    localStorage.removeItem(this.config.isSuperadminLocalStorageName)
    localStorage.removeItem(this.config.isLoggedInLocalStorageName)
    this.router.navigate(['login'])
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
}
