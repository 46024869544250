import { Component, OnInit, Input } from '@angular/core'
import { Subject } from 'rxjs'
import { Company } from '../../../Models/Company'
import { ReporterService } from '../../../Services/reporter/reporter.service'
import { DashboardComponent } from "../../dashboard/dashboard.component"
import { clickData } from "../selection-form/selection-form.component"

@Component({
  selector: 'app-excel-report',
  templateUrl: './excel-report.component.html',
  styleUrls: ['./excel-report.component.css'],
})
export class ExcelReportComponent implements OnInit {

  @Input() parentSubject: Subject<any>
  @Input() parent: DashboardComponent

  type: string = '0'
  startMonth = "0"
  endMonth = "0"
  selectedYear = new Date().getFullYear()
  selectedCompany: Company
  today: Date
  loading: boolean = false

  constructor(private reporter: ReporterService) {

  }

  ngOnInit() {
    this.today = new Date()
    this.parentSubject.subscribe(json => {
      this.selectedCompany = json.company
      this.selectedYear = json.year
    })
  }

  validate() {
    this.parent.message = ""

    if (this.type === '0') {
      this.parent.badMessage = true
      this.parent.message = "נא לבחור את סוג הדוח"
      return false
    }
    if (this.startMonth === '0') {
      this.parent.badMessage = true
      this.parent.message = 'נא לבחור חודש התחלה'
      return false
    }
    if (this.endMonth === '0') {
      this.parent.badMessage = true
      this.parent.message = 'נא לבחור חודש סיום'
      return false
    }
    if (parseInt(this.startMonth) > parseInt(this.endMonth)) {
      this.parent.badMessage = true
      this.parent.message = "חודש התחלה לא יכול להיות גדול מחודש סיום"
      return false
    }
    if (!this.selectedCompany) {
      this.parent.badMessage = true
      this.parent.message = 'נא לבחור חברה'
      return false
    }
    return true
  }

  async makeReport() {
    this.loading = true

    if (!this.validate()) {
      this.loading = false
      return
    }

    // Start loading
    this.parent.badMessage = false
    this.parent.message = "מנפיק..."

    let res
    if (this.type === '1') {
      res = await this.reporter.getReport(this.selectedCompany.id, this.startMonth, this.endMonth, this.selectedYear)
    } else if (this.type === '2') {
      res = await this.reporter.getAggregationReport(this.selectedCompany.id, this.startMonth, this.endMonth, this.selectedYear)
    }else if (this.type === '3') {
      res = await this.reporter.getTxtReport(this.selectedCompany.id, this.startMonth, this.endMonth, this.selectedYear)
    }

    if (!res || !res['success'] || res['message']) {
      this.parent.badMessage = true
      if (res['message']) {
        this.parent.message = res['message']
        this.loading = false
        return
      }
      this.parent.message = 'ישנה בעיה בהורדת הקובץ, נסה שוב בעוד כמה דקות.'
      this.loading = false
      return
    }

    // Downloads
    this.parent.badMessage = false
    this.parent.message = "מוריד..."

    if (this.type === '3') {
      const fileName = res['name']
      await this.reporter.downloadTxtReport(fileName)
    } else {
      const fileName = res['fileName']
      await this.reporter.downloadReport(fileName)
    }

    // Succeeded
    this.parent.badMessage = false
    this.parent.message = "הונפק בהצלחה"

    this.loading = false

    // Clear the message after finished downloading
    setTimeout(() => {
      this.parent.message = ''
    }, 2500)
  }

  async handleClick({type, startMonth, endMonth}: clickData) {
    this.type = type
    this.startMonth = startMonth
    this.endMonth = endMonth

    await this.makeReport()
  }

}
