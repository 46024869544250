import { Component, Input, OnInit, TemplateRef } from '@angular/core'
import { CompaniesService } from '../../../Services/CompaniesService/companies.service'
import { SortCode } from '../../../Models/SortCode'
import { Company } from '../../../Models/Company'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConfigService } from '../../../Services/config/config.service'
import { SortCodeType } from "../../../Enums/sort-code-type"
import strings from "../../../helpers/strings"
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import { BottomSheetErrorComponent } from "../../bottom-sheet-error/bottom-sheet-error.component"


@Component({
  selector: 'app-edit-sort-codes',
  templateUrl: './edit-sort-codes.component.html',
  styleUrls: ['./edit-sort-codes.component.css'],
})
export class EditSortCodesComponent implements OnInit {

  @Input() selectedUsername: string
  companies: [Company]
  selectedCompany: Company
  selectedId: string
  selectedSortCode: SortCode
  sortCodesArray: SortCode[] = []
  modalRef: BsModalRef
  editForm: FormGroup
  oldAccountKey: string
  newSortCodesArray: SortCode[] = []

  loading = false
  file: File
  onUploadProcess = false
  message: string
  badMessage: boolean = false
  SortCodeType = SortCodeType

  constructor(private companiesService: CompaniesService,
              private modalService: BsModalService,
              public config: ConfigService,
              private formBuilder: FormBuilder,
              private _bottomSheet: MatBottomSheet) {
  }

  displayError(): void {
    this._bottomSheet.open(BottomSheetErrorComponent, {data: {message: this.message, badMessage: this.badMessage}})
  }

  ngOnInit() {
    this.loading = true

    this.companiesService.getCompaniesList().then(res => {
      const allCompanies = res['companies']
      if (!allCompanies) {
        this.message = 'אין לך עדיין חברות'
        this.badMessage = true
        this.loading = false
        return
      }
      this.companies = allCompanies
      if (this.selectedUsername) {
        this.selectedCompany = allCompanies.filter(company => company.username === this.selectedUsername)[0]
        this.setSelectedCompanyAndGetSortCodes().then(value => value).catch(error => console.error(error))
      }
      this.loading = false
    })
  }

  async getSortCodes(selectedId: string): Promise<SortCode[]> {
    this.loading = true
    this.message = ''
    this.badMessage = false

    // Fetch the company's classifications
    const res = await this.companiesService.getCompanyClassifications(selectedId)
    if (!res['success']) {
      this.message = 'אין אפשרות למשוך את הסיווגים ממסד הנתונים'
      this.badMessage = true
      this.loading = false
      return []
    }

    const sortCodes = res['classifications'] || []
    if (!sortCodes.length) {
      this.message = `לחברה זו אין קודי מיון.`
      this.badMessage = false
      this.loading = false
      return []
    }

    this.loading = false
    return sortCodes
  }

  async setSelectedCompanyAndGetSortCodes(selectedId?: string) {
    this.message = ''
    this.badMessage = false

    if (selectedId) {
      this.selectedCompany = this.companies.find(company => company.id == selectedId)
    }
    this.selectedId = this.selectedCompany.id

    this.sortCodesArray = await this.getSortCodes(this.selectedCompany.id)
  }

  async copySortCodesFromOtherCompanyToSelectedCompany(otherId: string) {
    if (!this.selectedCompany) {
      this.message = 'נא בחר חברה להעתיק אליו את הקודי מיון'
      this.badMessage = true
    }

    this.newSortCodesArray = await this.getSortCodes(otherId)
  }

  async deleteSortCode() {
    const res = await this.companiesService.deleteSortCode(this.selectedCompany.id, this.selectedSortCode.accountKey)

    if (!res['success']) {
      alert(res['message'])
      return
    }
    await this.refreshData()
    this.modalRef.hide()
  }

  openDeleteModal(sortCode, template: TemplateRef<any>) {
    this.selectedSortCode = sortCode
    this.modalRef = this.modalService.show(template)
  }

  openEditModal(template: TemplateRef<any>, sortCode: SortCode) {
    this.oldAccountKey = sortCode.accountKey
    this.selectedSortCode = sortCode
    this.editForm = this.formBuilder.group({
      sortCode: [sortCode.sortCode, Validators.pattern('^[0-9]*$')],
      sortName: [sortCode.sortName],
      accountKey: [sortCode.accountKey, Validators.pattern('^[0-9]*$')],
      accountName: [sortCode.accountName],
      type: [sortCode.type, Validators.pattern(`(${SortCodeType.Regular}|${SortCodeType.Property}|${SortCodeType.Income})`)],
      vatPercent: [sortCode.vatPercent.toFixed(2), Validators.pattern('[0-9]+(\.[0-9][0-9]?)?')],
    })

    this.modalRef = this.modalService.show(template)
  }

  async closeEditModal(toUpdate) {
    if (!this.editForm.valid) {
      alert('הטופס לא חוקי')
      return
    }

    if (!toUpdate) {
      this.modalRef.hide()
      return
    }

    const updated = new SortCode()
    updated.accountKey = this.editForm.controls['accountKey'].value
    updated.accountName = this.editForm.controls['accountName'].value
    updated.type = this.editForm.controls['type'].value
    updated.sortName = this.editForm.controls['sortName'].value
    updated.sortCode = this.editForm.controls['sortCode'].value
    updated.vatPercent = this.editForm.controls['vatPercent'].value

    const res = await this.companiesService.updateSortCode(this.selectedCompany.id, updated, this.oldAccountKey)

    if (res['success']) {
      await this.refreshData()
      this.modalRef.hide()
      return
    }
    alert(res['message'])
  }

  async finishSortCodes(sortCodes: SortCode[]) {
    this.message = ''
    this.badMessage = false

    // If did not add any new sort code
    this.newSortCodesArray = sortCodes.filter(value => value.accountKey
      || value.accountName
      || value.type
      || value.sortCode
      || value.sortName
      || value.vatPercent)

    if (!this.validateSortCodes()) {
      console.log("Invalid sort")
      this.displayError()
      return
    }

    if (!this.selectedCompany) {
      this.message = 'לא בחרת חברה לשמירת הקודי מיון'
      this.badMessage = true
      this.displayError()
      return
    }

    const res = await this.companiesService.insertSortCodesBulk(this.selectedCompany.id, this.newSortCodesArray)
    if (res['success']) {
      this.newSortCodesArray = []
      await this.refreshData()
      return
    }
    this.message = res['message']
    this.badMessage = true
    this.displayError()
  }

  validateSortCodes() {
    this.badMessage = true
    if (this.newSortCodesArray.length == 0) return false
    const twoDecimals = new RegExp('[0-9]+(\.[0-9][0-9]?)?')

    for (let sc of this.newSortCodesArray) {
      console.log(sc)
      if (!sc.accountKey || !sc.sortCode || !sc.sortName || !sc.accountName) {
        this.message = 'נא למלא את כל התאים בשורה'
        return false
      }
      // if (isNaN(<any>sc.sortCode)) {
      //   this.message = 'מספר קוד מיון חייב להיות מספר'
      //   return false
      // }
      // if (isNaN(<any>sc.accountKey)) {
      //   this.message = 'מפתח חשבון חייב להיות מספר'
      //   return false
      // }
      if (isNaN(<any>sc.vatPercent)) {
        this.message = 'אחוז מעמ חייב להיות מספר'
        return false
      }
      if (sc.vatPercent > 100) {
        this.message = 'אחוז מעמ לא יכול להיות יותר מ-100'
        return false
      }
      // if (!strings.isHebrew(sc.sortName)) {
      //   this.message = 'שם קוד מיון יכול להיות רק אותיות בעברית ומספרים'
      //   return false
      // }
      // if (!strings.isHebrew(sc.accountName)) {
      //   this.message = 'שם חשבון יכול להיות רק אותיות בעברית ומספרים'
      //   return false
      // }
      if (<any>sc.sortCode < 0) {
        this.message = 'מספר קוד מיון מקבל רק מפסר חיובי'
        return false
      }
      if (<any>sc.accountKey < 0) {
        this.message = 'מפתח חשבון מקבל רק מפסר חיובי'
        return false
      }
      if (<any>sc.vatPercent < 0) {
        this.message = 'אחוז מעמ מקבל רק מפסר חיובי'
        return false
      }
      if (!twoDecimals.test(<any>sc.vatPercent)) {
        sc.vatPercent = <any>sc.vatPercent.toFixed(2)
      }
    }
    return true
  }

  async refreshData() {
    await this.setSelectedCompanyAndGetSortCodes()
  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files.item(0)
      event.target.value = ''
    }
  }

  async uploadCsvFile() {
    if (!this.file) return

    const fileExt = this.file.name.split('.').pop()
    if (fileExt != "csv") return

    if (!this.selectedCompany) {
      alert("חברה לא נבחרה")
      return
    }

    this.onUploadProcess = true
    const res = await this.companiesService.insertSortCodesCsv(this.selectedCompany.id, this.file)
    if (res['success']) {
      alert('קודי המיון נוספו בהצלחה!')
      this.file = undefined
      this.onUploadProcess = false
      await this.refreshData()
      return
    }
    alert(res['message'])
    this.onUploadProcess = false
  }
}
