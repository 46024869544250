export class Company {
  LocalId?: number
  id?: string
  name: string
  unapproved?: number
  username: string
  creditKey: string
  accountVatIncome: string
  accountVatInput: string
  accountVatInputProperty: string
  password: string
  email: string
  phone: string
  amountField: boolean
  classificationField: boolean
  referenceField: boolean
  commentField: boolean
  unapprovedCount?: { [key: string]: number }
  shouldUseAutoFill?:boolean;
}
