import { Component, Input, OnInit } from '@angular/core';
import { SortCode } from "../../../Models/SortCode"

@Component({
  selector: 'app-profit-loss-table',
  templateUrl: './profit-loss-table.component.html',
  styleUrls: ['./profit-loss-table.component.css'],
})
export class ProfitLossTableComponent implements OnInit {

  @Input() sortCodes: SortCode[]
  @Input() sortCodesSum: {}
  @Input() incomeSortCodesSum: {}
  @Input() sum: number
  incomeSortCodes: SortCode[]

  constructor() {
  }

  ngOnInit() {
    // this.incomeSortCodes = this.sortCodes.filter(value => this.incomeKeys.includes(parseInt(value.accountKey)))

  }

}
