import { Invoice } from "../Models/Invoice"


export default {

  /**
   * Calculate the given invoice's debits.
   * debitOne is the price - the vat
   * debitTwo is the vat
   *
   * @param value
   * @param globalVat
   * @param vatPercent
   */
  calculateDebits(value: number, globalVat: number, vatPercent?: number | string) {
    // Make sure it's a number for the calculation
    vatPercent = Number(vatPercent)

    // If no vat percent was given, set it as 0
    if (vatPercent === null) {
      vatPercent = 0
    }

    // Can't set more than 100 percent
    if (vatPercent > 100) vatPercent = 100

    // Can't set less than 0 percent
    if (vatPercent < 0) vatPercent = 0

    // The value minus the vat
    const debitOne = this.calculateDebitOne(value, globalVat, vatPercent)

    // The vat of the given value
    const debitTwo = value - debitOne

    // Make sure to have only 2 numbers after the decimal
    const vatPercentSlices = vatPercent.toString().split('.')

    // Only if it is a decimal number
    if (vatPercentSlices.length === 2) {
      vatPercent = `${+vatPercentSlices[0]}.${vatPercentSlices[1].slice(0, 2)}`
    }

    return {
      debitOne, debitTwo, vatPercent,
    }
  },

  /**
   * Calculate the given value minus its vat.
   *
   * @param value
   * @param globalVat
   * @param vatPercent
   */
  calculateDebitOne(value: number, globalVat: number, vatPercent: number) {
    let tempVat
    if (vatPercent.toFixed(2) == '66.66') tempVat = (2 / 3)
    else tempVat = vatPercent / 100

    const fullVat = value - (value / 1.17)
    return value - fullVat + (fullVat * (1 - tempVat))
  },

  /**
   * Calculate debits and save it to the invoice instance.
   *
   * @param invoice
   * @param globalVat
   */
  calculateInvoiceDebits(invoice: Invoice, globalVat: number) {
    const {debitOne, debitTwo, vatPercent} = this.calculateDebits(invoice.price, globalVat, invoice.vatPercent)

    invoice.debitOne = debitOne
    invoice.debitTwo = debitTwo
    invoice.vatPercent = vatPercent
  }
}
