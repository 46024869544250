import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConfigService } from '../../../Services/config/config.service'
import { AdminService } from '../../../Services/admin-service/admin.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'

@Component({
  selector: 'app-update-admin',
  templateUrl: './update-admin.component.html',
  styleUrls: ['./update-admin.component.css']
})
export class UpdateAdminComponent implements OnInit {

  public form: FormGroup
  formClasses: object
  public admins: any[]
  private finalUsername
  private finalId
  message: string
  badMessage: boolean
  searchInput: string
  foundAdmins: Array<any>
  selectedAdmin: any
  loading: boolean
  modalRef: BsModalRef
  Array = Array

  constructor(private formBuilder: FormBuilder,
              private config: ConfigService,
              private adminService: AdminService,
              private modalService: BsModalService) {
    this.refreshData()
    this.message = 'טוען נתונים...'
  }

  ngOnInit() {
  }

  refreshData() {
    this.form = undefined
    this.finalId = undefined
    this.searchInput = ""
    this.selectedAdmin = undefined
    this.foundAdmins = undefined
    this.adminService.getSubAdmins().then(res => {
      if (!res) return
      if (!res['success']) {
        alert(JSON.stringify(res))
        return
      }
      this.admins = res['subAdmins']
      if (this.admins.length === 0) {
        this.message = 'אין מנהלים'
      }
    })
  }

  buildForm(selectedId?: string) {
    this.message = ''
    this.selectedAdmin = this.admins.find(company => company.id == selectedId)

    let selected = this.selectedAdmin

    this.finalUsername = selected.username
    this.finalId = selected.id

    this.form = this.formBuilder.group({
      name: [selected.name, Validators.required],
      username: [selected.username, Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.pattern(this.config.PasswordRegExp)])]
    })
    this.form.controls['username'].disable()

    this.formClasses = {
      name: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
    }
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid': 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }

  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הטופס לא תקין!'
      return
    }

    this.loading = true
    this.adminService.updateSubAdmin(
      this.finalId,
      this.form.controls['accountName'].value,
      this.form.controls['password'].value).then(res => {
      if ([res['success']]) {
        this.badMessage = false
        this.message = 'הפעולה בוצעה בהצלחה!'
      } else {
        this.badMessage = true
        this.message = JSON.stringify(res)
      }
      this.loading = false
    })
  }

  deleteAdmin() {
    this.modalRef.hide()
    this.loading = true
    this.adminService.deleteSubAdmin(this.finalId).then(res => {
      if (res['success']) {
        this.badMessage = false
        this.message = 'הפעולה בוצעה בהצלחה!'
      } else {
        this.badMessage = true
        this.message = JSON.stringify(res)
      }
      this.refreshData()
      this.loading = false
    })
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }



}
