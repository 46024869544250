import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type clickData = {
  type: string,
  startMonth: string,
  endMonth: string,
}

@Component({
  selector: 'app-selection-form',
  templateUrl: './selection-form.component.html',
  styleUrls: ['./selection-form.component.css'],
})
export class SelectionFormComponent implements OnInit {

  @Input() types: string[]
  @Input() type: string = '1'
  @Input() startMonth = "0"
  @Input() endMonth = "0"
  @Input() year: number
  @Input() loading: boolean = false
  @Input() btnText: string
  @Input() btnIcon: string

  @Output() onClick = new EventEmitter<clickData>()

  months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

  get clickEventData(): clickData {
    return {
      type: this.type,
      startMonth: this.startMonth,
      endMonth: this.endMonth,
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  handleClick() {
    this.onClick.emit(this.clickEventData)
  }
}
