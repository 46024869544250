import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConfigService } from '../config/config.service'
import { Firm } from "../../Models/Firm"

@Injectable()
export class AdminService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  public createFirm(body: Firm) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.post(this.config.adminsEndpoint, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getFirms() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.get(this.config.adminsEndpoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public deleteFirm(adminId) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.delete(this.config.adminsEndpoint + '/' + adminId, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public updateFirm(adminId, body: Firm) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.put(this.config.adminsEndpoint + '/' + adminId, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getLatestTermsOfUse() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.get(this.config.LatestTermsOfUseEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public createTermsOfUse(text: string) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    const data = {
      text
    }
    return new Promise((resolve) => {
      this.http.post(this.config.LatestTermsOfUseEndPoint, data, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getAdminsTermsOfUse() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.get(this.config.getTermsOfUseEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public adminAgreedToTermsOfUse(version: number) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }
    const data = {
      version
    }
    return new Promise((resolve) => {
      this.http.post(this.config.AdminAgreeToTermsEndPoint, data, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public countSubAdmins() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.get(this.config.countSubAdminsEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public createSubAdmin(name, username, password) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    let body = {
      name,
      username,
      password,
    }
    return new Promise((resolve) => {
      this.http.post(this.config.subAdminsEndPoint, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public getSubAdmins() {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.get(this.config.subAdminsEndPoint, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public deleteSubAdmin(adminId) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    return new Promise((resolve) => {
      this.http.delete(this.config.subAdminsEndPoint + '/' + adminId, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  public updateSubAdmin(adminId, name, password) {
    let options = {
      headers: new HttpHeaders({
        "access-token": localStorage.getItem(this.config.TokenLocalStorageName)
      })
    }

    let body = {
      name,
      password,
    }

    return new Promise((resolve) => {
      this.http.put(this.config.subAdminsEndPoint + '/' + adminId, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }
}
