import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CompaniesService } from '../../../Services/CompaniesService/companies.service'
import { Company } from '../../../Models/Company'
import { ConfigService } from '../../../Services/config/config.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.css']
})
export class UpdateCompanyComponent implements OnInit {

  companies: [Company]
  selectedCompany: Company
  selectedId: string
  finalCompany
  form: FormGroup
  formClasses: object
  companyId: string
  modalRef: BsModalRef
  showForm: boolean = false
  message: string
  badMessage: boolean

  constructor(private companiesService: CompaniesService,
              private formBuilder: FormBuilder,
              private config: ConfigService,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.refreshData()
  }

  buildForm(selectedId?: string) {
    this.selectedCompany = this.companies.find(company => company.id == selectedId)

    this.message = ''
    let selected = this.selectedCompany

    this.finalCompany = selected

    this.companyId = selected.id
    selected.password = undefined

    this.form = this.formBuilder.group({
      companyName: [selected.name, Validators.pattern(this.config.CompanyNameRegExp)],
      username: [{ value: selected.username, disabled: true }, Validators.pattern(this.config.UserNameRegExp)],
      creditKey: [selected.creditKey, Validators.pattern(this.config.NumbersOnlyRegExp)],
      accountVatIncome: [selected.accountVatIncome, Validators.pattern(this.config.NumbersOnlyRegExp)],
      accountVatInput: [selected.accountVatInput, Validators.pattern(this.config.NumbersOnlyRegExp)],
      accountVatInputProperty: [selected.accountVatInputProperty, Validators.pattern(this.config.NumbersOnlyRegExp)],
      phone: [selected.phone, [Validators.pattern(this.config.PhoneNumberRegExp), Validators.minLength(9)]],
      email: [selected.email, Validators.email],
      password: [null],
      amountField: [selected.amountField],
      classificationField: [selected.classificationField],
      referenceField: [selected.referenceField],
      commentField: [selected.commentField],
      shouldUseAutoFill: [selected.shouldUseAutoFill]
    })
    this.showForm = true

    this.formClasses = {
      companyName: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
      creditKey: ['form-control'],
      phone: ['form-control'],
      email: ['form-control'],
      accountVatIncome: ['form-control'],
      accountVatInput: ['form-control'],
      accountVatInputProperty: ['form-control'],
      amountField: ['form-check-input'],
      classificationField: ['form-check-input'],
      referenceField: ['form-check-input'],
      commentField: ['form-check-input'],
      shouldUseAutoFill: ['form-check-input'],
    }
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid': 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }

  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הטופס לא תקין!'
      return
    }
    let newCompany = new Company()
    newCompany.username = this.form.controls['username'].value
    newCompany.name = this.form.controls['companyName'].value
    newCompany.creditKey = this.form.controls['creditKey'].value
    newCompany.accountVatIncome = this.form.controls['accountVatIncome'].value
    newCompany.accountVatInput = this.form.controls['accountVatInput'].value
    newCompany.accountVatInputProperty = this.form.controls['accountVatInputProperty'].value
    newCompany.password = this.form.controls['password'].value
    newCompany.email = this.form.controls['email'].value
    newCompany.phone = this.form.controls['phone'].value
    newCompany.amountField = this.form.controls['amountField'].value
    newCompany.classificationField = this.form.controls['classificationField'].value
    newCompany.referenceField = this.form.controls['referenceField'].value
    newCompany.commentField = this.form.controls['commentField'].value
    newCompany.shouldUseAutoFill = this.form.controls['shouldUseAutoFill'].value

    this.companiesService.updateCompanyDetails(this.companyId, newCompany).then(res => {
      if (res['success']) {
        this.badMessage = false
        this.message = 'הפרטים שונו בהצלחה'
        return
      }
      this.badMessage = true
      this.message = JSON.stringify(res['message'])
    })
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }

  deleteCompany() {
    this.companiesService.deleteCompany(this.finalCompany.id).then(res => {
      if (res['success']) {
        this.badMessage = false
        this.message = this.finalCompany.username + " נמחקה בהצלחה"
        this.modalRef.hide()
        this.refreshData()
        return
      }
      this.badMessage = true
      this.message = JSON.stringify(res['message'])
    })
  }

  refreshData() {
    this.showForm = false
    this.companies = undefined
    this.companiesService.getCompaniesList().then(res => {
      if (!res['companies']) {
        this.badMessage = true
        this.message = 'אין אפשרות למשוך את רשימת החברות ממסד הנתונים'
        return
      }
      this.companies = res['companies']
    })
  }

}
