import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortCode } from "../../../../Models/SortCode"
import strings from "../../../../helpers/strings"
import { SortCodeType } from 'app/Enums/sort-code-type';

@Component({
  selector: 'app-new-sort-codes-table',
  templateUrl: './new-sort-codes-table.component.html',
  styleUrls: ['./new-sort-codes-table.component.css'],
})
export class NewSortCodesTableComponent implements OnInit {

  @Input() sortCodes: SortCode[]
  @Input() startEmpty: boolean = false
  @Output() onSave = new EventEmitter<SortCode[]>()

  displayedColumns = ['delete', 'sortCode', 'sortName', 'accountName', 'accountKey', 'vatPercent', 'type']

  isHebrew = strings.isHebrew
  SortCodeType = SortCodeType

  constructor() {
  }

  ngOnInit() {
    if (!this.sortCodes) {
      this.sortCodes = this.startEmpty ? [] : [new SortCode()]
    }
  }

  addLine() {
    this.sortCodes = [...this.sortCodes, new SortCode()]
  }

  removeLine(index) {
    if (index !== -1) {
      this.sortCodes = [...this.sortCodes.slice(0, index), ...this.sortCodes.slice(index + 1, this.sortCodes.length)]
    }
  }

  save() {
    this.onSave.emit(this.sortCodes)
  }
}
