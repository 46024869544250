import { Component, OnInit } from '@angular/core'
import { LoginService } from '../../../Services/Login/login.service'
import { Router } from '@angular/router'
import { ConfigService } from '../../../Services/config/config.service'
import { AuthGuardService } from '../../../guards/auth-guard/auth-guard.service'
import { AuthSuperGuardGuard } from "../../../guards/super-guard/auth-super-guard.guard"
import { AdminService } from "../../../Services/admin-service/admin.service"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  loading: boolean = false
  username: string
  password: string
  failed: boolean
  public termsText: string
  private latestVersion: number
  private adminVersion: number
  public askForAgreement: boolean = false

  constructor(private loginService: LoginService,
              private adminService: AdminService,
              private authGuard: AuthGuardService,
              private authSuperGuard: AuthSuperGuardGuard,
              private router: Router,
              private configService: ConfigService) {
  }

  async ngOnInit() {
    if (this.authSuperGuard.canActivate() || this.authGuard.canActivate()) {
      await this.checkTermsAgreement()
    }
  }

  async checkTermsAgreement() {
    const res = await this.adminService.getAdminsTermsOfUse()
    if (!res) {
      alert(res['message'])
      return
    }
    if (!res['success']) {
      alert(res['message'])
      return
    }
    this.termsText = res['text']

    this.latestVersion = res['latestVersion']
    this.adminVersion = res['adminVersion']

    if (this.latestVersion === this.adminVersion) {
      this.askForAgreement = false
      this.onAgreed(true)
    } else {
      this.askForAgreement = true
    }
  }

  navigate() {
    if (this.authSuperGuard.canActivate()) {
      this.router.navigate(['superadmin'])
    } else if (this.authGuard.canActivate()) {
      this.router.navigate(['dashboard'])
    }
  }

  disconnect() {
    localStorage.removeItem(this.configService.UserNameLocalStorageName)
    localStorage.removeItem(this.configService.TokenLocalStorageName)
    localStorage.removeItem(this.configService.isMainAdminLocalStorageName)
    localStorage.removeItem(this.configService.isSuperadminLocalStorageName)
    localStorage.removeItem(this.configService.isLoggedInLocalStorageName)
    this.askForAgreement = false
  }

  saveToLocalStorage(res) {
    localStorage.setItem(this.configService.isLoggedInLocalStorageName, 'true')
    localStorage.setItem(this.configService.UserNameLocalStorageName, this.username)
    localStorage.setItem(this.configService.FirmNameLocalStorageName, res['firmName'])
    localStorage.setItem(this.configService.isMainAdminLocalStorageName, res['isMainAdmin'])
    localStorage.setItem(this.configService.isSuperadminLocalStorageName, res['isSuperadmin'])
    localStorage.setItem(this.configService.TokenLocalStorageName, res['token'])
  }

  onAgreed(agreed: boolean) {
    if (agreed) {
      this.navigate()
    } else {
      this.disconnect()
    }
  }

  async login() {
    this.failed = false
    this.loading = true
    const res = await this.loginService.adminLogin(this.username, this.password)

    if (!res || !res['success']) {
      this.failed = true
      this.loading = false
      return
    }

    this.saveToLocalStorage(res)

    await this.checkTermsAgreement()
    this.loading = false
  }
}
