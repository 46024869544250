import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component'
import { AdminService } from "../../../Services/admin-service/admin.service"
// polyfills.js
import 'zone.js/dist/zone.js'
(window as any).__Zone_disable_toString = true

@Component({
  selector: 'app-generate-terms-of-use',
  templateUrl: './generate-terms-of-use.component.html',
  styleUrls: ['./generate-terms-of-use.component.css']
})
export class GenerateTermsOfUseComponent implements OnInit {

  public editor = ClassicEditor
  public editorData: string = ''
  public initialData: string = ''
  public config = {
    contentsLangDirection: 'rtl'
  }
  public isEdited = false
  public saved = false

  constructor(private adminService: AdminService) { }

  public onChange( { editor }: ChangeEvent ) {
    if (this.initialData !== editor.getData()) {
      this.isEdited = true
    }
    this.saved = false
    // this.editorData = editor.getData()
  }

  async ngOnInit() {

    const res = await this.adminService.getLatestTermsOfUse()
    if (!res) return
    if (!res['success']) alert(res['message'])

    this.initialData = res['text']
    this.editorData = this.initialData
    this.isEdited = false
  }

  async onSave() {
    const res = await this.adminService.createTermsOfUse(this.editorData)
    if (!res) alert('שגיאה בשמירה, נסו שוב מאוחר יותר')
    if (!res['success']) alert(res['message'])

    this.isEdited = false
    this.saved = true
  }
}
