import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Company } from "../../Models/Company"
import { Admin } from "../../Models/Admin"

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css']
})
export class SearchSelectComponent implements OnInit {

  @Input() inline: boolean = false
  @Input() selectedId: string = '0'
  @Input() subjectName: string
  // @Input() subjectList: Company[] | Admin[]
  @Output() outputSelectedId = new EventEmitter<string>()
  foundSubjects: Company[] | Admin[]
  selectedSubject: Company | Admin
  // selectedId: string = '0'
  searchInput: string
  private _subjectList: Company[] | Admin[]

  @Input() set subjectList(value: Company[] | Admin[]) {
    // if (this._subjectList) {
    //   this.selectedId = '0'
    // }
    this._subjectList = value
  }

  get subjectList(): Company[] | Admin[] {
    return this._subjectList
  }

  constructor() { }

  ngOnInit() {
  }

  search($event) {
    if (!$event.target.value) return
    if ($event.key === "Enter") {
      if (this.foundSubjects && this.foundSubjects.length > 0) {
        this.selectedSubject = this.foundSubjects[0]
        this.setSelectedCompany()
      }
      return
    }
    if ($event.target.value.length < 1) return
    const value = $event.target.value.toLowerCase()
    if (!value) {
      this.foundSubjects = []
      return
    }

    if ((<Company[]>this.subjectList).filter) {
      this.foundSubjects = (<Company[]>this.subjectList).filter(company => company.name.toLowerCase().includes(value))
    } else {
      this.foundSubjects = (<Admin[]>this.subjectList).filter(company => company.name.toLowerCase().includes(value))
    }
  }

  setSelectedCompany(fromId?) {
    if (fromId) {
      if ((<Company[]>this.subjectList).filter) {
        this.selectedSubject = (<Company[]>this.subjectList).find(company => company.id == this.selectedId)
      } else {
        this.selectedSubject = (<Admin[]>this.subjectList).find(company => company.id == this.selectedId)
      }
    }
    this.selectedId = this.selectedSubject.id
    this.searchInput = ''
    this.foundSubjects = []
    this.outputSelectedId.emit(this.selectedId)
  }

}
