import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConfigService } from "../../Services/config/config.service"

@Injectable({
  providedIn: 'root',
})
export class AuthSuperGuardGuard implements CanActivate {
  constructor(private config: ConfigService, private router: Router) {
  }

  canActivate(): boolean {
    if (localStorage.getItem(this.config.isLoggedInLocalStorageName)) {
      if (localStorage.getItem(this.config.isSuperadminLocalStorageName) == 'true') {
        return true
      }
      this.router.navigate(['dashboard'])
    }
    this.router.navigate(['login'])
    return false
  }

}
