//Modules
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { SidebarModule } from 'ng-sidebar'
import { DatePipe } from '@angular/common'
import { MyDatePickerModule } from 'mydatepicker'
import { MaterialModule } from './material.module'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material'


// Components
import { AppComponent } from './app.component'
import { CreateCompanyComponent } from './Components/admin/create-company/create-company.component'
import { UpdateCompanyComponent } from './Components/admin/update-company/update-company.component'
import { EditSortCodesComponent } from './Components/admin/edit-sort-codes/edit-sort-codes.component'
import { AdminDashboardComponent } from './Components/admin/admin-dashboard/admin-dashboard.component'
import { ExcelReportComponent } from './Components/reports/excel-report/excel-report.component'
import { DashboardComponent } from './Components/dashboard/dashboard.component'
import { SortInvoicesComponent } from './Components/sort-invoices/sort-invoices.component'
import { HomeComponent } from './Components/home/home.component'
import { LoginComponent } from './Components/login/login/login.component'

//Services
import { ConfigService } from './Services/config/config.service'
import { CompaniesService } from './Services/CompaniesService/companies.service'
import { InvoicesUpdaterService } from './Services/invoices-updater/invoices-updater.service'
import { LoginService } from './Services/Login/login.service'
import { ReporterService } from './Services/reporter/reporter.service'

//Guards
// import { AdminGuardService } from './guards/admin-guard/admin-guard.service'
import { AuthGuardService as AuthGuard } from './guards/auth-guard/auth-guard.service'
import { AuthSuperGuardGuard as AuthSuperGuard } from './guards/super-guard/auth-super-guard.guard'
import { ImagePopUpComponent } from './others/image-pop-up/image-pop-up.component'
import { CreateAdminComponent } from './Components/admin/create-admin/create-admin.component'
import { AdminService } from './Services/admin-service/admin.service'
import { UpdateAdminComponent } from './Components/admin/update-admin/update-admin.component';
import { SearchSelectComponent } from './Components/search-select/search-select.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { InvoicesTableComponent } from './Components/invoices-table/invoices-table.component';
import { ReportsComponent } from './Components/reports/reports/reports.component';
import { SuperadminDashboardComponent } from './Components/superadmin/superadmin-dashboard/superadmin-dashboard.component';
import { CreateOfficeComponent } from './Components/superadmin/create-office/create-office.component';
import { UpdateOfficeComponent } from './Components/superadmin/update-office/update-office.component';
import { ProfitLossTableComponent } from './Components/reports/profit-loss-table/profit-loss-table.component';
import { VatsTableComponent } from './Components/reports/vats-table/vats-table.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TermsOfUseComponent } from './Components/terms-of-use/terms-of-use.component';
import { GenerateTermsOfUseComponent } from './Components/superadmin/generate-terms-of-use/generate-terms-of-use.component';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular"
import { LoginTermsOfUseComponent } from "./Components/login/login-terms-of-use/login-terms-of-use.component";
import { NewSortCodesTableComponent } from './Components/admin/edit-sort-codes/new-sort-codes-table/new-sort-codes-table.component';
import { BottomSheetErrorComponent } from './Components/bottom-sheet-error/bottom-sheet-error.component';
import { SelectionFormComponent } from './Components/reports/selection-form/selection-form.component';

//Routes
const appRoutes: Routes = [
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: {animation: 'HomePage'}},
  {path: 'admin', component: AdminDashboardComponent, canActivate: [AuthGuard], data: {animation: 'AdminPage'}},
  {path: 'superadmin', component: SuperadminDashboardComponent, canActivate: [AuthSuperGuard], data: {animation: 'SuperadminPage'}},
  {path: 'login', component: LoginComponent},
  {path: 'login-terms-of-use', component: TermsOfUseComponent},
  {path: 'image/:companyId/:folder/:id', component: ImagePopUpComponent},
  {path: '**', component: LoginComponent, data: {animation: 'LoginPage'}},
]


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SortInvoicesComponent,
    LoginComponent,
    DashboardComponent,
    ExcelReportComponent,
    AdminDashboardComponent,
    CreateCompanyComponent,
    UpdateCompanyComponent,
    EditSortCodesComponent,
    ImagePopUpComponent,
    CreateAdminComponent,
    UpdateAdminComponent,
    SearchSelectComponent,
    InvoicesTableComponent,
    ReportsComponent,
    SuperadminDashboardComponent,
    CreateOfficeComponent,
    UpdateOfficeComponent,
    CreateOfficeComponent,
    ProfitLossTableComponent,
    VatsTableComponent,
    TermsOfUseComponent,
    LoginTermsOfUseComponent,
    GenerateTermsOfUseComponent,
    NewSortCodesTableComponent,
    BottomSheetErrorComponent,
    SelectionFormComponent,
  ],
  entryComponents: [BottomSheetErrorComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    MyDatePickerModule,
    MaterialModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CKEditorModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
  ],
  providers: [
    ConfigService,
    LoginService,
    CompaniesService,
    DatePipe,
    InvoicesUpdaterService,
    AuthGuard,
    ReporterService,
    // AdminGuardService,
    AdminService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
