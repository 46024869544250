import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { CompaniesService } from '../../../Services/CompaniesService/companies.service'
import { ConfigService } from '../../../Services/config/config.service'
import { Company } from "../../../Models/Company"

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css'],
})
export class CreateCompanyComponent implements OnInit {

  @Input() selectedIndex: Number
  @Output() selectedIndexChange = new EventEmitter<Number>()
  @Input() selectedUsername: Number
  @Output() selectedUsernameChange = new EventEmitter<string>()
  form: FormGroup
  formClasses: object
  loading: boolean
  succeeded = false
  message: string
  badMessage: boolean = false
  company: Company
  count: number
  maxAllowed: number
  limitExceeded: boolean

  constructor(private formBuilder: FormBuilder, private config: ConfigService, private companiesService: CompaniesService) {
    this.buildForm()
  }

  ngOnInit() {
  }

  countAdmins(dontLimit: boolean = false) {
    this.companiesService.countCompanies().then(res => {
      if (!res) return
      if (!res['success']) {
        alert(JSON.stringify(res))
        return
      }
      this.maxAllowed = res['maxAllowed']
      this.count = res['count']
      if (!dontLimit) this.limitExceeded = this.count >= this.maxAllowed
    })
  }

  buildForm() {
    // Check if not exceeded the limit
    this.countAdmins()
    if (this.limitExceeded) return

    this.form = this.formBuilder.group({
      companyName: ['', Validators.pattern(this.config.CompanyNameRegExp)],
      username: ['', Validators.pattern(this.config.UserNameRegExp)],
      password: ['', Validators.pattern(this.config.PasswordRegExp)],
      creditKey: ['', Validators.pattern(this.config.NumbersOnlyRegExp)],
      phone: ['', [Validators.pattern(this.config.PhoneNumberRegExp), Validators.minLength(9)]],
      email: ['', Validators.email],
      accountVatIncome: ['', Validators.pattern(this.config.NumbersOnlyRegExp)],
      accountVatInput: ['', Validators.pattern(this.config.NumbersOnlyRegExp)],
      accountVatInputProperty: ['', Validators.pattern(this.config.NumbersOnlyRegExp)],
      amountField: [true],
      classificationField: [true],
      referenceField: [true],
      commentField: [true],
      shouldUseAutoFill: [false]
    })

    this.formClasses = {
      companyName: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
      creditKey: ['form-control'],
      phone: ['form-control'],
      email: ['form-control'],
      accountVatIncome: ['form-control'],
      accountVatInput: ['form-control'],
      accountVatInputProperty: ['form-control'],
      amountField: ['form-check-input'],
      classificationField: ['form-check-input'],
      referenceField: ['form-check-input'],
      commentField: ['form-check-input'],
      shouldUseAutoFill: ['form-check-input'],
    }
  }

  makeNew() {
    this.buildForm()
    this.succeeded = false
    this.message = ''
  }

  goToSortCodes() {
    this.selectedUsernameChange.emit(this.company.username)
    this.selectedIndexChange.emit(2)
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid': 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }

  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הטופס לא תקין'
      return
    }
    this.message = ""
    this.loading = true
    const body: Company = {
      name: this.form.controls['companyName'].value,
      username: this.form.controls['username'].value,
      password: this.form.controls['password'].value,
      creditKey: this.form.controls['creditKey'].value,
      accountVatIncome: this.form.controls['accountVatIncome'].value,
      accountVatInput: this.form.controls['accountVatInput'].value,
      accountVatInputProperty: this.form.controls['accountVatInputProperty'].value,
      email: this.form.controls['email'].value,
      phone: this.form.controls['phone'].value,
      amountField: this.form.controls['amountField'].value,
      classificationField: this.form.controls['classificationField'].value,
      referenceField: this.form.controls['referenceField'].value,
      commentField: this.form.controls['commentField'].value,
      shouldUseAutoFill: this.form.controls['shouldUseAutoFill'].value,
    }
    this.companiesService.createCompany(body).then(res => {
      if (res['success']) {
        this.succeeded = true
        this.badMessage = false
        this.message = 'החברה נוצרה בהצלחה!'
        this.loading = false
        this.company = res['company']
        // Check if not exceeded the limit
        this.countAdmins(true)
        return
      }
      this.badMessage = true
      this.message = res['message']
      this.loading = false
    })
  }

}
