import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet"

@Component({
  selector: 'app-bottom-sheet-error',
  templateUrl: './bottom-sheet-error.component.html',
  styleUrls: ['./bottom-sheet-error.component.css'],
})
export class BottomSheetErrorComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetErrorComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: { message: string, badMessage: boolean }) {
  }

  ngOnInit() {
  }

}
