import { NgModule } from '@angular/core'
import {
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatTableModule,
  MatRadioModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatBottomSheetModule,
} from "@angular/material"


@NgModule({
  imports: [
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTableModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatProgressBarModule,
  ],
  exports: [
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTableModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatProgressBarModule,
  ],
})
export class MaterialModule {
}
