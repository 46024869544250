import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from "../../Services/admin-service/admin.service"

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  @Input() text: string

  constructor(private adminService: AdminService) { }

  async ngOnInit() {
    const res = await this.adminService.getAdminsTermsOfUse()
  }

  onAgreed() {

  }

  onCanceled() {

  }
}
