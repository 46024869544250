import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from "../../../Services/admin-service/admin.service"

@Component({
  selector: 'app-login-terms-of-use',
  templateUrl: './login-terms-of-use.component.html',
  styleUrls: ['./login-terms-of-use.component.css']
})
export class LoginTermsOfUseComponent implements OnInit {

  @Input() text: string
  @Input() version: number
  @Output() agreed = new EventEmitter<boolean>()
  public loading: boolean = false

  constructor(private adminService: AdminService) { }

  ngOnInit() {
  }

  async onAgreed() {
    this.loading = true
    const res = await this.adminService.adminAgreedToTermsOfUse(this.version)
    if (!res) alert('בעית חיבור עם השרת')
    if (!res['success']) alert(res['message'])
    else {
      this.agreed.emit(true)
    }
    this.loading = false
  }

  onCanceled() {
    this.agreed.emit(false)
  }

}
