import { Component, OnInit, TemplateRef } from '@angular/core'
import { ConfigService } from '../../Services/config/config.service'
import { Router } from '@angular/router'
import { Company } from '../../Models/Company'
import { Subject } from 'rxjs'
import { CompaniesService } from "../../Services/CompaniesService/companies.service"
import { animate, style, transition, trigger } from "@angular/animations"
import { BsModalRef, BsModalService } from "ngx-bootstrap"
import { AuthGuardService } from "../../guards/auth-guard/auth-guard.service"
import { BottomSheetErrorComponent } from "../bottom-sheet-error/bottom-sheet-error.component"
import { FormBuilder } from "@angular/forms"
import { MatBottomSheet, MatBottomSheetRef } from "@angular/material/bottom-sheet"


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({top: -20}),
        animate(250, style({top: 0})),
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({top: -10})),
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1})),
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({opacity: 0})),
      ]),
    ]),
  ],
})
export class DashboardComponent implements OnInit {

  public companies: Company[]
  public selectedCompany: Company
  public maxSelectedYear: number = new Date().getFullYear()
  public selectedYear: number = this.maxSelectedYear
  parentSubject: Subject<any> = new Subject()
  firmName: string
  loading = true
  isCollapsed = true
  homeVisible: boolean = true
  isOn: string = 'home'
  globalVat: number
  modalRef: BsModalRef

  errorSheet: MatBottomSheetRef<BottomSheetErrorComponent>
  private _message: string
  public badMessage: boolean

  set message(value: string) {
    this._message = value

    if (value) {
      this.displayError()
    } else {
      this.clearError()
    }
  }

  get message() {
    return this._message
  }

  get parentSubjectData() {
    return {
      company: this.selectedCompany,
      year: this.selectedYear,
      globalVat: this.globalVat,
    }
  }

  constructor(private config: ConfigService,
              private router: Router,
              private companiesService: CompaniesService,
              private modalService: BsModalService,
              private _bottomSheet: MatBottomSheet) {
    this.firmName = localStorage.getItem(this.config.FirmNameLocalStorageName)
    this.getCompanies()
  }

  displayError(): void {
    this.errorSheet = this._bottomSheet.open(BottomSheetErrorComponent, {data: {message: this.message, badMessage: this.badMessage}})
  }

  clearError(): void {
    if (this.errorSheet) {
      this.errorSheet.dismiss()
    }
  }

  ngOnInit() {

    const regularYear = document.querySelector('.year-input-wrapper')
    window.addEventListener('scroll', event => {
      this.homeVisible = DashboardComponent.isInViewport(regularYear)
    }, false)

    // Get the global vat value
    this.config.getGlobalVat().then(res => {
      if (res) {
        this.globalVat = res['vat']

        // Push the globalVat to the children components
        this.parentSubject.next(this.parentSubjectData)
      }
    })
  }

  static isInViewport(elem) {
    const bounding = elem.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  refresh() {
    this.getCompanies()
    this.parentSubject.next(this.parentSubjectData)
  }

  getCompanies() {
    this.loading = true
    const selectedCompany = this.selectedCompany

    this.companiesService.getCompaniesList().then(data => {
      if (!data) return
      this.companies = data['companies']
      this.loading = false
      this.selectedCompany = selectedCompany
    })
  }

  public scroll(el: any): void {
    el.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  goToAdminPanel() {
    this.router.navigate(['admin'])
  }

  setSelectedCompany(selectedId?: string) {
    this.message = ''

    if (selectedId) {
      this.selectedCompany = this.companies.find(company => company.id == selectedId)
    }

    if (!this.selectedCompany) {
      this.badMessage = true
      this.message = 'נא לבחור חברה!'
      return
    } else if (!this.selectedYear) {
      this.badMessage = true
      this.message = 'נא לבחור שנה!'
      return
    }

    this.parentSubject.next(this.parentSubjectData)
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  disconnect() {
    this.modalRef.hide()
    localStorage.removeItem(this.config.UserNameLocalStorageName)
    localStorage.removeItem(this.config.TokenLocalStorageName)
    localStorage.removeItem(this.config.isMainAdminLocalStorageName)
    localStorage.removeItem(this.config.isSuperadminLocalStorageName)
    localStorage.removeItem(this.config.isLoggedInLocalStorageName)
    this.router.navigate(['login'])
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
}
