import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  //General
  public ApiUrl: string = "https://app2.gdcpa.co.il/"

  //Api Admin Endpoints.
  public AdminLoginEndPoint = this.ApiUrl + "admin/login"
  public AdminAgreeToTermsEndPoint = this.ApiUrl + "admin/termsAgreement"
  public companiesEndPoint = this.ApiUrl + "admin/companies"
  public countCompaniesEndPoint = this.ApiUrl + "admin/countCompanies"
  public subAdminsEndPoint = this.ApiUrl + "admin/subAdmins"
  public countSubAdminsEndPoint = this.ApiUrl + "admin/countSubAdmins"
  public InsertSortCodesBulkEndPoint = this.ApiUrl + "admin/sortCodesBulk"
  public UploadCsvSortCodesEndPoint = this.ApiUrl + "admin/sortCodesCsv"
  public sortCodesEndPoint = this.ApiUrl + "admin/sortCodes"
  public invoicesEndPoint = this.ApiUrl + "admin/invoices"
  public getApprovedInvoicesReportEndPoint = this.ApiUrl + "admin/approvedInvoicesReport"
  public getAggregationReportEndPoint = this.ApiUrl + "admin/aggregationReport"
  public downloadReportEndPoint = this.ApiUrl + "admin/downloadReadyReport"
  public getTxtReportEndPoint = this.ApiUrl + "admin/txtReport"
  public downloadTxtReportEndPoint = this.ApiUrl + "admin/downloadTxtReport"
  public report1EndPoint = this.ApiUrl + "admin/reports1"
  public report2EndPoint = this.ApiUrl + "admin/reports2"
  public getTermsOfUseEndPoint = this.ApiUrl + "admin/termsOfUse"


  // Api SuperAdmin endpoints
  public adminsEndpoint = this.ApiUrl + "superadmin/admins"
  public LatestTermsOfUseEndPoint = this.ApiUrl + "superadmin/termsOfUse"

  //Api Common Endpoints.
  public getImageEndPoint = this.ApiUrl + "common/image"
  public getAttachedFileEndpoint = this.ApiUrl + "common/attached"
  public baseSortCodesCsvEndPoint = this.ApiUrl + "common/sortCodeBase"
  public getCompanyClassificationsEndPoint = this.ApiUrl + "common/classifications"
  public globalVatEndPoint = this.ApiUrl + "common/vat"

  //Local Storage Names
  public TokenLocalStorageName = "dHdjyJLksXS+6L/N3SFso/CI"
  public isLoggedInLocalStorageName = "OaKFmVsy5961hqqiSBJ9pMMXwTsX2mqIVqRQuAoIPYmXiqiNqsS5h89Rt86Q4K9RdPOQi/6CUPQzLhb90CAUaC4OpCGdsLf9ayDkkuLg55qQ6l80K3XujziaoHFt3pwN"
  public UserNameLocalStorageName = "qRvl1GNy6tr1gny1IH178++ggptve0QE0klKcSSQzdhVGIT3zvh8bGpahoF8qJUVPkH10SyRymAVS9wydCUQo/MX8jbWGaux6csvoE/JM1JbeEgrT6Hg3rGwLPKd64jv"
  public FirmNameLocalStorageName = "1gny1IH178++ggsdgfgklfudhVGIT3zvh8bGpahoF8qJUVPJbeEgrT6Hg3rqRvl1GNy6trGwLPKVS9wydCUQo/MX8jbWGaux6csvoE/d64kH10dgdsdSyRymAJM1jt56v"
  public isMainAdminLocalStorageName = "tr1gny1IH178+qgptve0QERvl1GNy6+g0klKcahoF8qMSSRdPOQi/6CUPQzLhb90CAUaC4OpCGQzdhVGIT3zvh8bGp1JbeEgrT6Hg3rGwLPKd66Q4K9dsLf9ayDkkuLJ"
  public isSuperadminLocalStorageName = "0QE0klKcSSQtr1gny1IH178+qRvl1GNy6+ggptvezdhVGIT3zvh8bGpahoF8q6Q4K9RdPOQi/6CUPQzLhb90CAUaC4OpCGdsLf9ayDkkuLJM1JbeEgrT6Hg3rGwLPKd6"


  //RegExps
  public AdminNameRegExp: RegExp = new RegExp('^[A-Za-z0-9א-ת._ ]*$')
  public CompanyNameRegExp: RegExp = new RegExp('^[A-Za-z0-9א-ת._ ]*$')
  public UserNameRegExp: RegExp = new RegExp('^[A-Za-z0-9_]*$')
  public PasswordRegExp: RegExp = new RegExp('^[A-Za-z0-9!@#$%^&*()_=-?><]*$')
  public PhoneNumberRegExp: RegExp = new RegExp(/^(?:(?:(972|\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/);
  public NumbersOnlyRegExp: RegExp = new RegExp('^[0-9]*$')


  constructor(private http: HttpClient) {
  }

  public getGlobalVat() {
    return new Promise((resolve) => {
      this.http.get(this.globalVatEndPoint).subscribe(res => {
        resolve(res)
      })
    })
  }


}
