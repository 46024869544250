import { Component, OnInit, TemplateRef } from '@angular/core'
import { ConfigService } from '../../../Services/config/config.service'
import { Router } from '@angular/router'
import { AuthGuardService } from "../../../guards/auth-guard/auth-guard.service"
import { BsModalRef, BsModalService } from "ngx-bootstrap"

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {

  selectedIndex: Number = 0
  selectedUsername: string
  isMainAdmin: boolean
  modalRef: BsModalRef

  constructor(private config: ConfigService, private router: Router, public authGuard: AuthGuardService, private modalService: BsModalService) {
    this.isMainAdmin = authGuard.isMainAdmin()
  }

  goToInvoices() {
    this.router.navigate(['dashboard']);
  }

  ngOnInit() {
  }

  disconnect() {
    this.modalRef.hide()
    localStorage.removeItem(this.config.UserNameLocalStorageName)
    localStorage.removeItem(this.config.TokenLocalStorageName)
    localStorage.removeItem(this.config.isMainAdminLocalStorageName)
    localStorage.removeItem(this.config.isSuperadminLocalStorageName)
    localStorage.removeItem(this.config.isLoggedInLocalStorageName)
    this.router.navigate(['login'])
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
}
