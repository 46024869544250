import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-vats-table",
  templateUrl: "./vats-table.component.html",
  styleUrls: ["./vats-table.component.css"],
})
export class VatsTableComponent implements OnInit {
  @Input() vats: {
    incomeVat: number;
    inputVat: number;
    propertyInputVat: number;
  };
  @Input() sum: number = 0;
  @Input()incomeSum:number = 0
  // }

  constructor() {}

  ngOnInit() {
  }
}
