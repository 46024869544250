import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ConfigService } from '../../../Services/config/config.service'
import { AdminService } from '../../../Services/admin-service/admin.service'
import { Firm } from "../../../Models/Firm"

@Component({
  selector: 'app-create-office',
  templateUrl: './create-office.component.html',
  styleUrls: ['./create-office.component.css'],
})
export class CreateOfficeComponent implements OnInit {
  form: FormGroup
  formClasses: object
  loading: boolean
  succeeded = false
  message: string
  badMessage: boolean
  defaultMaxCompanies: number = 50
  defaultMaxSubAdmins: number = 10
  defaultMaxCompaniesWithAutoFill: number = 0
  defaultMaxAutoFillPerCompany: number = 150

  constructor(private formBuilder: FormBuilder,
              private config: ConfigService,
              private adminService: AdminService) {
    this.buildForm()
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.pattern(this.config.AdminNameRegExp)],
      firmId: ['', Validators.pattern(this.config.PasswordRegExp)],
      phone: ['', Validators.pattern(this.config.PhoneNumberRegExp)],
      email: ['', Validators.email],
      username: ['', Validators.pattern(this.config.UserNameRegExp)],
      password: ['', Validators.pattern(this.config.PasswordRegExp)],
      maxCompanies: [this.defaultMaxCompanies, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxSubAdmins: [this.defaultMaxSubAdmins, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxCompaniesWithAutoFill: [this.defaultMaxCompaniesWithAutoFill, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxAutoFillPerCompany: [this.defaultMaxAutoFillPerCompany, Validators.pattern(this.config.NumbersOnlyRegExp)],
    })

    this.formClasses = {
      name: ['form-control'],
      firmId: ['form-control'],
      phone: ['form-control'],
      email: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
      maxCompanies: ['form-control'],
      maxSubAdmins: ['form-control'],
      maxCompaniesWithAutoFill: ['form-control'],
      maxAutoFillPerCompany: ['form-control'],
    }
  }

  ngOnInit() {
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid': 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }
  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הנתונים שהוכנסו אינם תקינים'
      return
    }

    const firm: Firm = {
      name: this.form.controls['name'].value,
      firmId: this.form.controls['firmId'].value,
      phone: this.form.controls['phone'].value,
      email: this.form.controls['email'].value,
      username: this.form.controls['username'].value,
      password: this.form.controls['password'].value,
      maxCompanies: this.form.controls['maxCompanies'].value,
      maxSubAdmins: this.form.controls['maxSubAdmins'].value,
      maxCompaniesWithAutoFill: this.form.controls['maxCompaniesWithAutoFill'].value,
      maxAutoFillPerCompany: this.form.controls['maxAutoFillPerCompany'].value,
    }

    this.adminService.createFirm(firm)
      .then(res => {
        if (res['success']) {
          this.badMessage = false
          this.message = 'המשרד נוצר בהצלחה!!'
          this.succeeded = true
          return
        }
        this.badMessage = true
        if (res['message']) {
          this.message = res['message']
        } else {
          this.message = 'ישנו בעיה בשמירת הנתונים בשרת, נסו שוב מאוחר יותר'
          console.log(JSON.stringify(res))
        }
      })
  }

  makeNew() {
    this.buildForm()
    this.succeeded = false
    this.message = ''
  }
}
