import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { BsModalRef, BsModalService } from "ngx-bootstrap"
import { ConfigService } from "../../../Services/config/config.service"
import { AdminService } from "../../../Services/admin-service/admin.service"
import { Firm } from "../../../Models/Firm"

@Component({
  selector: 'app-update-office',
  templateUrl: './update-office.component.html',
  styleUrls: ['./update-office.component.css'],
})
export class UpdateOfficeComponent implements OnInit {

  public form: FormGroup
  formClasses: object
  public admins: any[]
  private finalFirmName
  private finalId
  message: string
  badMessage: boolean
  searchInput: string
  foundAdmins: Array<any>
  selectedAdmin: any
  loading: boolean
  modalRef: BsModalRef
  Array = Array

  constructor(private formBuilder: FormBuilder,
              private config: ConfigService,
              private adminService: AdminService,
              private modalService: BsModalService) {
    this.refreshData()
    this.message = 'טוען נתונים...'
  }

  ngOnInit() {
  }

  refreshData() {
    this.form = undefined
    this.finalId = undefined
    this.searchInput = ""
    this.selectedAdmin = undefined
    this.foundAdmins = undefined
    this.adminService.getFirms().then(res => {
      if (!res) return
      if (!res['success']) {
        alert(JSON.stringify(res))
        return
      }
      this.admins = res['admins']
      if (this.admins.length === 0) {
        this.message = 'אין מנהלים'
      }
    })
  }

  buildForm(selectedId?: string) {
    this.message = ''
    this.selectedAdmin = this.admins.find(company => company.id == selectedId)

    let selected = this.selectedAdmin

    this.finalFirmName = selected.name
    this.finalId = selected.id

    this.form = this.formBuilder.group({
      name: [selected.name, [Validators.required, Validators.pattern(this.config.AdminNameRegExp)]],
      firmId: [selected.firmId, Validators.pattern(this.config.PasswordRegExp)],
      phone: [selected.phone, [Validators.pattern(this.config.PhoneNumberRegExp), Validators.minLength(9)]],
      email: [selected.email, Validators.email],
      username: [selected.username, Validators.required],
      password: ['', Validators.compose([Validators.pattern(this.config.PasswordRegExp)])],
      maxCompanies: [selected.maxCompanies, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxSubAdmins: [selected.maxSubAdmins, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxCompaniesWithAutoFill: [selected.maxCompaniesWithAutoFill, Validators.pattern(this.config.NumbersOnlyRegExp)],
      maxAutoFillPerCompany: [selected.maxAutoFillPerCompany, Validators.pattern(this.config.NumbersOnlyRegExp)],
    })
    this.form.controls['username'].disable()

    this.formClasses = {
      name: ['form-control'],
      firmId: ['form-control'],
      phone: ['form-control'],
      email: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
      maxCompanies: ['form-control'],
      maxSubAdmins: ['form-control'],
      maxCompaniesWithAutoFill: ['form-control'],
      maxAutoFillPerCompany: ['form-control'],
    }
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid' : 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }

  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הטופס לא תקין!'
      return
    }

    this.loading = true

    const change: Firm = {
      name: this.form.controls['name'].value,
      firmId: this.form.controls['firmId'].value,
      phone: this.form.controls['phone'].value,
      email: this.form.controls['email'].value,
      password: this.form.controls['password'].value,
      maxCompanies: this.form.controls['maxCompanies'].value,
      maxSubAdmins: this.form.controls['maxSubAdmins'].value,
      maxCompaniesWithAutoFill: this.form.controls['maxCompaniesWithAutoFill'].value,
      maxAutoFillPerCompany: this.form.controls['maxAutoFillPerCompany'].value,
    }

    this.adminService.updateFirm(this.finalId, change).then(res => {
      if ([res['success']]) {
        this.badMessage = false
        this.message = 'הפעולה בוצעה בהצלחה!'
      } else {
        this.badMessage = true
        this.message = JSON.stringify(res)
      }
      this.loading = false
    })
  }

  deleteAdmin() {
    this.modalRef.hide()
    this.loading = true
    this.adminService.deleteFirm(this.finalId).then(res => {
      if (res['success']) {
        this.badMessage = false
        this.message = 'הפעולה בוצעה בהצלחה!'
      } else {
        this.badMessage = true
        this.message = JSON.stringify(res)
      }
      this.refreshData()
      this.loading = false
    })
  }

  openAreYouSure(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
}
