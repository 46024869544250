import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable()
export class InvoicesUpdaterService {

  constructor(private http: HttpClient, private config: ConfigService) {
  }

  updateInvoice(companyId: number, invoiceId: number, changes) {
    let options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    let body = {
      changes,
    }
    const url = this.config.invoicesEndPoint + '/' + companyId + '/' + invoiceId

    return new Promise((resolve) => {
      this.http.put(url, body, options).subscribe(res => {
        resolve(res)
      })
    })
  }

  deleteInvoice(companyId: number, invoiceId: number) {
    let options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    const url = this.config.invoicesEndPoint + '/' + companyId + '/' + invoiceId

    return new Promise((resolve) => {
      this.http.delete(url, options).subscribe(res => {
        resolve(res)
      })
    })
  }
}
