import { Injectable } from '@angular/core';
import { ConfigService } from '../../Services/config/config.service';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private config: ConfigService, private router: Router) {
  }


  public canActivate(): boolean {

    if (localStorage.getItem(this.config.isLoggedInLocalStorageName)) {
      return true
    }
    this.router.navigate(['login'])
    return false
  }

  public isSuperadmin(): boolean {

    return localStorage.getItem(this.config.isSuperadminLocalStorageName) == 'true'
  }

  public isMainAdmin(): boolean {

    return localStorage.getItem(this.config.isMainAdminLocalStorageName) == 'true'
  }

}
