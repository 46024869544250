import { Component, Input, OnInit } from "@angular/core";
import { DashboardComponent } from "../../dashboard/dashboard.component";
import { Subject } from "rxjs";
import { Company } from "../../../Models/Company";
import { ConfigService } from "../../../Services/config/config.service";
import { Invoice } from "../../../Models/Invoice";
import { SortCode } from "../../../Models/SortCode";
import { CompaniesService } from "../../../Services/CompaniesService/companies.service";
import { SortCodeType } from "../../../Enums/sort-code-type";
import calculations from "../../../helpers/calculations";
import { clickData } from "../selection-form/selection-form.component";
import { log } from "util";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  @Input() parent: DashboardComponent;
  @Input() parentSubject: Subject<any>;
  type: string = "0";
  startMonth = "0";
  endMonth = "0";
  selectedCompany: Company;
  selectedYear: number;
  globalVat: number;
  debit: number = 0;
  sortCodes: SortCode[];
  sortCodesSum: {};
  incomeSortCodesSum: {};
  sum: number;
  vats: {
    incomeVat: number;
    inputVat: number;
    propertyInputVat: number;
  } = {
    incomeVat: 0,
    inputVat: 0,
    propertyInputVat: 0,
  };
  incomeKeys: number[];
  nonPropertyNonIncomeKeys: number[];
  propertyKeys: number[];
  isUpToDate: boolean = true;
  loading: boolean = false;
  incomeSum: number = 0;
  constructor(
    protected config: ConfigService,
    private companiesService: CompaniesService
  ) {}

  ngOnInit() {
    this.parentSubject.subscribe(async (json) => {
      this.parent.message = "";

      const prevCompany = this.selectedCompany;
      const prevYear = this.selectedYear;

      this.selectedCompany = json.company;
      this.selectedYear = json.year;

      // Check for changes
      if (
        this.selectedCompany !== prevCompany ||
        this.selectedYear !== prevYear
      ) {
        this.isUpToDate = false;
      }

      if (json.globalVat) {
        this.globalVat = json.globalVat;
      }
    });
  }

  /**
   * Convert a MySql datetime string to JS Date object
   *
   * @param invoices
   */
  convertMysqlDateToJsDate(invoices): [Invoice] {
    return invoices.map((invoice) => {
      invoice.invoiceDate = new Date(Date.parse(invoice.invoiceDate));
      invoice.uploadDate = new Date(Date.parse(invoice.uploadDate));
      invoice.demandDate = new Date(Date.parse(invoice.demandDate));

      return invoice;
    });
  }

  calculateDebits(invoice) {
    calculations.calculateInvoiceDebits(invoice, this.globalVat);
  }

  async loadSortCodes() {
    const res = await this.companiesService.getCompanyClassifications(
      this.selectedCompany.id
    );
    if (!res || !res["classifications"]) {
      console.log(res);
      this.parent.badMessage = true;
      this.parent.message = "יש בעיה בקבלת מידע מהשרת";
      return;
    }
    const classifications = res["classifications"];
    for (let c of classifications) {
      const sc: SortCode = <SortCode>c;
      this.sortCodes.push(sc);
    }
    this.incomeKeys = this.sortCodes
      .filter((value) => value.type === SortCodeType.Income)
      .map((value) => parseInt(value.accountKey));
    this.nonPropertyNonIncomeKeys = this.sortCodes
      .filter((value) => value.type == SortCodeType.Regular)
      .map((value) => parseInt(value.accountKey));
    this.propertyKeys = this.sortCodes
      .filter((value) => value.type === SortCodeType.Property)
      .map((value) => parseInt(value.accountKey));
  }

  async loadApprovedInvoices(): Promise<Invoice[]> {
    const res = await this.companiesService.getCompanyInvoicesList(
      this.selectedCompany.id,
      this.selectedYear
    );
    if (!res || !res["success"]) {
      console.log(res);
      this.parent.badMessage = true;
      this.parent.message = "יש בעיה בקבלת מידע מהשרת";
      return;
    }
    return this.convertMysqlDateToJsDate(res["approvedInvoices"]);
  }

  async load() {
    // reset in memory
    this.sortCodes = [];
    this.sortCodesSum = {};
    this.incomeSortCodesSum = {};
    this.sum = 0;
    this.vats = {
      incomeVat: 0,
      inputVat: 0,
      propertyInputVat: 0,
    };

    if (this.selectedCompany) {
      await this.loadSortCodes();

      const invoices = await this.loadApprovedInvoices();

      for (let inv of invoices) {
        // If the invoice is not in the selected moths range, continue.
        const month = inv.demandDate.getUTCMonth() + 1;
        if (
          month > parseInt(this.endMonth) ||
          month < parseInt(this.startMonth)
        ) {
          continue;
        }

        // calculate the debits (debitOne, debitTwo) and save it onto the inv instance
        this.calculateDebits(inv);

        if (this.type === "1") {
          this.makeProfitLossReport(inv);
        } else if (this.type === "2") {
          this.makeVatReport(inv);
        }
      }
    }
  }

  /**
   * Profit Loss will sum all the income claus invoice with a positive num,
   * and sum all other clauses with a negative num,
   * and then set the sum to the sum of all the clauses.
   *
   * @param inv
   */
  makeProfitLossReport(inv: Invoice) {
    const classification = inv.classification;

    // If the invoice is an income, add it to income sum,
    // else, added it to sum object with their key
    if (this.incomeKeys.includes(classification)) {
      if (!(classification in this.incomeSortCodesSum)) {
        this.incomeSortCodesSum[classification] = 0;
      }
      this.incomeSortCodesSum[classification] += inv.debitOne;
      this.sum += inv.debitOne;
    } else {
      if (!(classification in this.sortCodesSum)) {
        this.sortCodesSum[classification] = 0;
      }
      this.sortCodesSum[classification] -= inv.debitOne;
      this.sum -= inv.debitOne;
    }
  }

  /**
   * Vat report will get separably the sum of the income vat,
   * the sum of the non property non income vat
   * and the sum of properties vat.
   *
   * @param inv
   */
  makeVatReport(inv: Invoice) {
    ////////////////////////////////
    const classificationTmp = inv.classification;

    // If the invoice is an income, add it to income sum,
    // else, added it to sum object with their key
    if (this.incomeKeys.includes(classificationTmp)) {
      if (!(classificationTmp in this.incomeSortCodesSum)) {
        this.incomeSortCodesSum[classificationTmp] = 0;
      }
      this.incomeSortCodesSum[classificationTmp] += inv.debitOne;
      this.sum += inv.debitOne;
    } else {
      if (!(classificationTmp in this.sortCodesSum)) {
        this.sortCodesSum[classificationTmp] = 0;
      }
      this.sortCodesSum[classificationTmp] -= inv.debitOne;
      this.sum -= inv.debitOne;
    }
    // ////////////////////////////////
    if (this.incomeSortCodesSum[classificationTmp])
      this.incomeSum = this.incomeSortCodesSum[classificationTmp];
    

    const classification = inv.classification;

    // If the invoice is an income, add it to income vat,
    // else, added it to sum object with their key
    if (this.incomeKeys.includes(classification)) {
      this.vats.incomeVat += inv.debitTwo;
      this.sum += inv.debitTwo;
    } else if (this.nonPropertyNonIncomeKeys.includes(classification)) {
      this.vats.inputVat -= inv.debitTwo;
      this.sum -= inv.debitTwo;
    } else if (this.propertyKeys.includes(classification)) {
      this.vats.propertyInputVat -= inv.debitTwo;
      this.sum -= inv.debitTwo;
    }
  }

  validate() {
    if (this.type === "0") {
      this.parent.badMessage = true;
      this.parent.message = "נא לבחור את סוג הדוח";
      return false;
    }
    if (this.startMonth == "0") {
      this.parent.badMessage = true;
      this.parent.message = "נא לבחור את חודש ההתחלה";
      return false;
    }
    if (this.endMonth == "0") {
      this.parent.badMessage = true;
      this.parent.message = "נא לבחור את חודש סיום";
      return false;
    }
    if (parseInt(this.startMonth) > parseInt(this.endMonth)) {
      this.parent.badMessage = true;
      this.parent.message = "תאריך התחלה לא יכול להיות אחרי תאריך סיום";
      return false;
    }
    if (!this.selectedCompany) {
      this.parent.badMessage = true;
      this.parent.message = "נא לבחור חברה";
      return false;
    }
    return true;
  }

  async makeReport() {
    this.loading = true;

    if (!this.validate()) {
      this.loading = false;
      return;
    }

    this.debit = 0;

    // Loads and creates the report
    await this.load();

    if (
      Object.keys(this.sortCodesSum).length === 0 &&
      Object.keys(this.incomeSortCodesSum).length === 0 &&
      this.sum === 0
    ) {
      this.parent.badMessage = true;
      this.parent.message = `אין חשבוניות בין חודשים: ${this.startMonth} - ${this.endMonth}`;
      this.loading = false;
      return;
    }

    this.loading = false;
    this.isUpToDate = true;
  }

  async handleClick({ type, startMonth, endMonth }: clickData) {
    this.incomeSum = 0;
    this.type = type;
    this.startMonth = startMonth;
    this.endMonth = endMonth;

    await this.makeReport();
  }
}
