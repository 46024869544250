import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ConfigService } from '../../Services/config/config.service'
import { DashboardComponent } from '../dashboard/dashboard.component'
import { Subject } from "rxjs"
import { Company } from "../../Models/Company"
import { error } from "util"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @Input() parent: DashboardComponent
  @Input() parentSubject: Subject<any>
  @Input() sortSectionEle: any
  @Output() selectedIdChange = new EventEmitter<string>()
  firmName: string
  selectedYear: number

  constructor(private config: ConfigService) {
    this.firmName = localStorage.getItem(this.config.FirmNameLocalStorageName)
  }

  hasUnapprovedCount({unapprovedCount}: Company) {
    return !(Object.entries(unapprovedCount).length === 0 && unapprovedCount.constructor === Object);
  }

  sumUnapprovedCount({unapprovedCount}: Company) {
    let sum = 0

    for (let year in unapprovedCount) {
      sum += unapprovedCount[year]
    }

    return sum
  }

  unapprovedYears({unapprovedCount}: Company) {
    return Object.keys(unapprovedCount)
  }

  ngOnInit() {
    if (this.parent) {
      this.selectedYear = this.parent.selectedYear
    }
    this.parentSubject.subscribe(async json => {
      if (json.year) {
        this.selectedYear = json.year
      }
    })
  }

  scrollAndSelectCompany(el: any) {
    setTimeout(() => this.parent.scroll(el), 250)
  }

  yearChanged() {
    if (this.parent) {
      this.parent.selectedYear = this.selectedYear
      this.parent.setSelectedCompany()
    }
  }
}
