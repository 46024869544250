import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Invoice } from "../../Models/Invoice";
import { APIStatus } from "../../Enums/APIStatus";
import { InvoicesUpdaterService } from "../../Services/invoices-updater/invoices-updater.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DatePipe } from "@angular/common";
import { ConfigService } from "../../Services/config/config.service";
import { InvoicesType } from "../../Enums/invoices-type.enum";
import { SortCode } from "../../Models/SortCode";
import { CompaniesService } from "../../Services/CompaniesService/companies.service";
import calculations from "../../helpers/calculations";

@Component({
  selector: "app-invoices-table",
  templateUrl: "./invoices-table.component.html",
  styleUrls: ["./invoices-table.component.css"],
})
export class InvoicesTableComponent implements OnInit {
  // A invoices typescript type to use in the html
  InvoicesType = InvoicesType;

  @ViewChild("tBody", { static: false }) tbody: ElementRef;
  @Input() title: string;
  @Input() invoices: Invoice[];
  @Input() classifications: SortCode[];
  @Input() type: InvoicesType;
  @Input() globalVat: number;
  @Input() loading: boolean = false;
  @Output() refresh = new EventEmitter<boolean>();
  selectedInvoice: Invoice;
  modalRef: BsModalRef;
  today: Date = new Date();
  classificationWidth: number = 100;
  commentWidth: number = 200;
  markedRowIndex: number = -1;

  sortByBiggerUploadDate: boolean = true;
  sortByBiggerDemandDate: boolean = true;
  sortByBiggerInvoiceDate: boolean = true;
  sortByBiggerPrice: boolean = true;

  constructor(
    private renderer: Renderer2,
    private invoicesUpdater: InvoicesUpdaterService,
    private config: ConfigService,
    private companiesService: CompaniesService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {}

  change(event) {
    // invoice.invoiceDate.setUTCMonth
    const date: string[] = (event.target.value as string).split("/");
    const newDate = new Date(
      parseInt(date[2]),
      parseInt(date[1]) - 1,
      parseInt(date[0])
    );
    console.log(newDate);
    return newDate;

    // console.log(new Date(event));
    // const date = (event.target.value as string).split("/");
    // invoice.invoiceDate.setFullYear(
    //   parseInt(date[2]),
    //   parseInt(date[1]),
    //   parseInt(date[0])
    // );
    // console.log(invoice.invoiceDate);
  }

  rowEvent(index: number, event) {
    const rows = this.tbody.nativeElement.rows as HTMLCollection;
    const { classList } = rows.item(index);
    const { tagName } = event.target;
    if (tagName === "INPUT" || tagName === "SELECT") {
      if (!classList.contains("selected")) classList.add("selected");
    } else {
      classList.contains("selected")
        ? classList.remove("selected")
        : classList.add("selected");
    }
    if (this.markedRowIndex != -1 && this.markedRowIndex !== index) {
      rows.item(this.markedRowIndex).classList.remove("selected");
    }
    this.markedRowIndex = index;
  }

  sortByUploadDate() {
    this.invoices.sort((a, b) => {
      // convert date object into number to resolve issue in typescript
      return this.sortByBiggerUploadDate
        ? +new Date(b.uploadDate) - +new Date(a.uploadDate)
        : +new Date(a.uploadDate) - +new Date(b.uploadDate);
    });
    this.sortByBiggerUploadDate = !this.sortByBiggerUploadDate;
  }

  sortByDemandDateDate() {
    this.invoices.sort((a, b) => {
      // convert date object into number to resolve issue in typescript
      return this.sortByBiggerDemandDate
        ? +new Date(b.demandDate) - +new Date(a.demandDate)
        : +new Date(a.demandDate) - +new Date(b.demandDate);
    });
    this.sortByBiggerDemandDate = !this.sortByBiggerDemandDate;
  }

  sortByInvoiceDateDateDate() {
    this.invoices.sort((a, b) => {
      // convert date object into number to resolve issue in typescript
      return this.sortByBiggerInvoiceDate
        ? +new Date(b.invoiceDate) - +new Date(a.invoiceDate)
        : +new Date(a.invoiceDate) - +new Date(b.invoiceDate);
    });
    this.sortByBiggerInvoiceDate = !this.sortByBiggerInvoiceDate;
  }

  sortByPrice() {
    this.invoices.sort((a, b) => {
      // convert date object into number to resolve issue in typescript
      return this.sortByBiggerPrice ? b.price - a.price : a.price - b.price;
    });
    this.sortByBiggerPrice = !this.sortByBiggerPrice;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const classificationSelector = document.querySelector(
        ".classification-cell"
      );
      if (classificationSelector) {
        this.classificationWidth = classificationSelector.getBoundingClientRect().width;
      }
    }, 1000);
  }

  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  isLate(invoice: Invoice) {
    return this.monthDiff(invoice.invoiceDate, this.today) > 1;
  }

  private getFolder(invoice) {
    let folder =
      invoice.uploadDate.getUTCMonth() + 1 > 9
        ? invoice.uploadDate.getMonth() + 1
        : "0" + (invoice.uploadDate.getMonth() + 1).toString();
    return <string>folder + "." + invoice.uploadDate.getFullYear();
  }

  commentFocusOut(event) {
    const target = event.currentTarget;
    const parent1 = target.parentElement.parentElement;
    const parent = parent1.parentElement;
    const firstChild = parent.children[0];
    if (target.value.length > 15) {
      if (!parent1.classList.contains("d-none")) {
        parent1.classList.add("d-none");
      }
      if (firstChild.classList.contains("d-none")) {
        firstChild.classList.remove("d-none");
      }
    }
  }

  openCommentModal(template: TemplateRef<any>, event, invoice: Invoice) {
    event.preventDefault();
    this.selectedInvoice = invoice;
    this.modalRef = this.modalService.show(template);
  }

  openImageModal(event, invoice: Invoice) {
    console.log(this.type);
    event.preventDefault();
    let changes = JSON.stringify(this.setChanges(invoice,0));
    // const classifications = JSON.stringify(this.classifications);
    const inv = JSON.stringify(invoice);
    if (
      invoice.imagePath != null &&
      invoice.imagePath.includes("admin/assets/uploads/")
    ) {
      const pathParts = invoice.imagePath.split("admin/assets/uploads/");
      window.open(
        `./image/${pathParts[1]}` +
          `?inv=${inv}&type=${this.type}&changes=${changes}`,
        "Image",
        "width=540,height=740"
      );
    } else {
      const folder = this.getFolder(invoice);
      window.open(
        "./image/" +
          invoice.companyId +
          "/" +
          folder +
          "/" +
          invoice.invoiceId +
          `?inv=${inv}&type=${this.type}&changes=${changes}`,
        "Image",
        "width=540,height=740"
      );
    }
  }

  /**
   * @param invoice
   */
  async downloadAttachedFile(invoice: Invoice) {
    const folder = this.getFolder(invoice);
    const id = invoice.invoiceId;
    const attach = invoice.attach;
    console.log(attach);

    // If the api returned a full link
    if (attach.includes("http")) {
      await this.companiesService.downloadAttach(attach);
      // window.open(attach)
    } else {
      window.open(
        `${this.config.getAttachedFileEndpoint}/${invoice.companyId}/${folder}/${id}`
      );
    }
  }

  classificationChange(invoice) {
    invoice.vatPercent = this.classifications[
      invoice.localClassificationId
    ].vatPercent;
    this.calculateDebits(invoice);
  }

  /**
   * Calculate the given invoice's debits.
   * debitOne is the price - the vat
   * debitTwo is the vat
   *
   * @param invoice
   */
  calculateDebits(invoice) {
    calculations.calculateInvoiceDebits(invoice, this.globalVat);
  }

  /**
   * Convert a MySql datetime string to JS Date object
   *
   * @param invoices
   */
  convertMysqlDateToJsDate(invoices): Invoice[] {
    return invoices.map((invoice) => {
      invoice.invoiceDate = new Date(Date.parse(invoice.invoiceDate));
      invoice.uploadDate = new Date(Date.parse(invoice.uploadDate));
      invoice.demandDate = new Date(Date.parse(invoice.demandDate));

      return invoice;
    });
  }

  approveInvoice(invoice: Invoice, event?) {
    if (event) event.preventDefault();
    // if (invoice.comment.indexOf("'") != -1) {
    //   alert("ההערה מכילה תווים לא חוקיים");
    //   return;
    // }

    // const changes = {
    //   reference: invoice.reference,
    //   comment: invoice.comment.replace("/'/g", "\\'"),
    //   price: invoice.price,
    //   classification: this.classifications[invoice.localClassificationId]
    //     ? this.classifications[invoice.localClassificationId].accountKey
    //     : null,
    //   invoiceDate: this.datePipe.transform(invoice.invoiceDate, "yyyy-MM-dd"),
    //   demandDate: this.datePipe.transform(invoice.demandDate, "yyyy-MM-dd"),
    //   vatPercent: invoice.vatPercent,
    //   isReported: invoice.isReported,
    // };
    let changes = {...this.setChanges(invoice,1)};
    // changes.isReported = invoice.isReported

    if (
      Object.values(changes).some(
        (item) => item !== 0 && (item == null || typeof item == "undefined")
      )
    ) {
      alert("אי אפשר לאשר חשבונית שחסרים לה פרטים");
      return;
    }

    // Set isApproved to true
    // changes["isApproved"] = 1;
    invoice.approveStatus = APIStatus.WaitingForApprove;

    // Update invoice
    this.invoicesUpdater
      .updateInvoice(invoice.companyId, invoice.id, changes)
      .then((res) => {
        if (!res || !res["success"]) {
          if (res["message"]) {
            alert(res["message"]);
          } else {
            alert("ישנה בעיה בעדכון החשבונית, נסה לרענן את הדף ולנסות שוב.");
          }
          invoice.approveStatus = APIStatus.None;
          return;
        }
        invoice.approveStatus = APIStatus.Done;
      });
    // this.refresh.emit(true)
  }

  deleteInvoice(invoice: Invoice, event?) {
    if (event) event.preventDefault();

    // Do nothing when the invoice is already deleted
    if (this.type === InvoicesType.Deleted) {
      return;
    }

    // let changes = {
    //   reference: invoice.reference,
    //   comment: invoice.comment.replace("/'/g", "\\'"),
    //   price: invoice.price,
    //   classification: this.classifications[invoice.localClassificationId]
    //     ? this.classifications[invoice.localClassificationId]!.accountKey
    //     : null,
    //   invoiceDate: this.datePipe.transform(invoice.invoiceDate, "yyyy-MM-dd"),
    //   demandDate: this.datePipe.transform(invoice.demandDate, "yyyy-MM-dd"),
    //   vatPercent: invoice.vatPercent,
    // };

    // changes["isApproved"] = -1;
    let changes = this.setChanges(invoice,-1);
    invoice.deleteStatus = APIStatus.WaitingForApprove;
    this.invoicesUpdater
      .updateInvoice(invoice.companyId, invoice.id, changes)
      .then((res) => {
        if (!res || !res["success"]) {
          if (res["message"]) {
            alert(res["message"]);
          } else {
            alert("עקב בעיה בשרת לא ניתן למחוק את החשבונית");
          }
          invoice.approveStatus = APIStatus.None;
          return;
        }
        invoice.deleteStatus = APIStatus.Done;
      });
    // this.refresh.emit(true)
  }

  setChanges(invoice: Invoice,approveStatus:number) {
    let changes = {
      reference: invoice.reference,
      comment: invoice.comment.replace("/'/g", "\\'"),
      price: invoice.price,
      classification: this.classifications[invoice.localClassificationId]
        ? this.classifications[invoice.localClassificationId]!.accountKey
        : null,
      invoiceDate: this.datePipe.transform(invoice.invoiceDate, "yyyy-MM-dd"),
      demandDate: this.datePipe.transform(invoice.demandDate, "yyyy-MM-dd"),
      vatPercent: invoice.vatPercent,
      isReported: invoice.isReported
    };

    changes["isApproved"] = approveStatus;

    return changes;
  }
}
