import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginService {

  constructor(private config: ConfigService, private http: HttpClient) {
  }

  adminLogin(username, password) {
    const body = {
      username,
      password,
    }

    console.log(this.config.AdminLoginEndPoint)
    console.log(body)
    return new Promise((resolve) => {
      this.http.post(this.config.AdminLoginEndPoint, body).subscribe(res => {
        resolve(res)
      })
    })
  }

}
