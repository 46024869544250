import { Component, OnInit, Input } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
import { ConfigService } from "../../Services/config/config.service";
import { Invoice } from "../../Models/Invoice";
import { InvoicesType } from "../../Enums/invoices-type.enum";
import { InvoicesTableComponent } from "../../Components/invoices-table/invoices-table.component";
import { InvoicesUpdaterService } from "../../Services/invoices-updater/invoices-updater.service";
import { CompaniesService } from "../../Services/CompaniesService/companies.service";
import { DatePipe } from "@angular/common";
import { SortCode } from "../../Models/SortCode";
import { APIStatus } from "../../Enums/APIStatus";
@Component({
  selector: "app-image-pop-up",
  templateUrl: "./image-pop-up.component.html",
  styleUrls: ["./image-pop-up.component.css"],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the accountName
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0deg)" })),
      state("one", style({ transform: "rotate(90deg) translateX(90px)" })),
      state("two", style({ transform: "rotate(180deg)" })),
      state("three", style({ transform: "rotate(270deg) translateX(-90px)" })),
      state("four", style({ transform: "rotate(360deg)" })),
      transition("default => one", animate("400ms ease-in")),
      transition("four => one", animate("400ms ease-in")),
      transition("one => two", animate("400ms ease-in")),
      transition("two => three", animate("400ms ease-in")),
      transition("three => four", animate("400ms ease-in")),
      transition("default => three", animate("400ms ease-in")),
      transition("four => three", animate("400ms ease-in")),
      transition("three => two", animate("400ms ease-in")),
      transition("two => one", animate("400ms ease-in")),
      transition("one => default", animate("400ms ease-in")),
    ]),
  ],
})
export class ImagePopUpComponent implements OnInit {
  invoicesType = InvoicesType;
  state: string = "default";
  url: string;
  width: number = 640;
  height: number = 300;
  invoice: Invoice;
  type: InvoicesType;
  changes: any;
  classifications: SortCode[];
  // invoicesTableComponent: InvoicesTableComponent = new InvoicesTableComponent(
  //   null,
  //   this.invoicesUpdater,
  //   this.config,
  //   this.companiesService,
  //   null,
  //   this.datePipe
  // );

  constructor(
    private route: ActivatedRoute,
    private config: ConfigService,
    private invoicesUpdater: InvoicesUpdaterService,
    private companiesService: CompaniesService,
    private datePipe: DatePipe
  ) {
    this.route.params.subscribe((params) => {
      const companyId = params["companyId"];
      const folder = params["folder"];
      const id = params["id"];
      this.url =
        this.config.getImageEndPoint +
        "/" +
        companyId +
        "/" +
        folder +
        "/" +
        id;
    });

    this.route.queryParams.subscribe((query) => {
      if (query["type"]) this.type = parseInt(query["type"]);
      if (query["inv"]) this.invoice = JSON.parse(query["inv"]);
      if (query["changes"]) this.changes = JSON.parse(query["changes"]);
      // if (query["classifications"])
      //   this.classifications = JSON.parse(query["classifications"]);
    });
  }

  ngOnInit() {}

  deleteInvoice(invoice, $event) {
    if (event) event.preventDefault();

    // Do nothing when the invoice is already deleted
    if (this.type === InvoicesType.Deleted) {
      return;
    }
    invoice.deleteStatus = APIStatus.WaitingForApprove;
    this.changes["isApproved"] = -1;
    this.invoicesUpdater
      .updateInvoice(invoice.companyId, invoice.id, this.changes)
      .then((res) => {
        if (!res || !res["success"]) {
          if (res["message"]) {
            console.log("sss");
            alert(res["message"]);
          } else {
            alert("עקב בעיה בשרת לא ניתן למחוק את החשבונית");
          }
          invoice.approveStatus = APIStatus.None;
          return;
        }
        invoice.deleteStatus = APIStatus.Done;
      });
  }

  approveInvoice(invoice, $event) {
    if (event) event.preventDefault();
    if (invoice.comment.indexOf("'") != -1) {
      alert("ההערה מכילה תווים לא חוקיים");
      return;
    }
    if (
      Object.values(this.changes).some(
        (item) => item !== 0 && (item == null || typeof item == "undefined")
      )
    ) {
      alert("אי אפשר לאשר חשבונית שחסרים לה פרטים");
      return;
    }
    this.changes["isApproved"] = 1;
    invoice.approveStatus = APIStatus.WaitingForApprove;
    this.invoicesUpdater
      .updateInvoice(invoice.companyId, invoice.id, this.changes)
      .then((res) => {
        if (!res || !res["success"]) {
          if (res["message"]) {
            alert(res["message"]);
          } else {
            alert("ישנה בעיה בעדכון החשבונית, נסה לרענן את הדף ולנסות שוב.");
          }
          invoice.approveStatus = APIStatus.None;
          return;
        }
        invoice.approveStatus = APIStatus.Done;
      });
  }

  rotateLeft() {
    switch (this.state) {
      case "default":
      case "four":
        this.state = "three";
        break;
      case "three":
        this.state = "two";
        break;
      case "two":
        this.state = "one";
        break;
      case "one":
        this.state = "default";
        break;
    }
  }

  rotateRight() {
    switch (this.state) {
      case "default":
      case "four":
        this.state = "one";
        break;
      case "one":
        this.state = "two";
        break;
      case "two":
        this.state = "three";
        break;
      case "three":
        this.state = "four";
        break;
    }
  }

  close() {
    window.close();
  }

  plus() {
    this.width += 50;
  }

  minus() {
    this.width -= 50;
  }
}
