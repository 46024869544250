import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import * as moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class ReporterService {

  constructor(private http: HttpClient, private config: ConfigService) {
  }

  async getReport1(companyId, fromDate, toDate) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }
    fromDate = moment(fromDate).format('YYYY-MM-DD')
    toDate = moment(toDate).format('YYYY-MM-DD')

    const url = this.config.report1EndPoint + '/' + companyId + '/' + fromDate + '/' + toDate

    const res = await this.http.get(url, options).toPromise()

    return Promise.resolve(res)
  }

  async getReport2(companyId, fromDate, toDate) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    fromDate = moment(fromDate).format('YYYY-MM-DD')
    toDate = moment(toDate).format('YYYY-MM-DD')

    const url = this.config.report2EndPoint + '/' + companyId + '/' + fromDate + '/' + toDate

    const res = await this.http.get(url, options).toPromise()

    return Promise.resolve(res)
  }

  async getReport(companyId, startMonth, endMonth, year) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    const url = this.config.getApprovedInvoicesReportEndPoint + '/' + companyId + '/' + year + '/' + startMonth + '/' + endMonth

    const res = await this.http.get(url, options).toPromise()

    return Promise.resolve(res)
  }

  async getAggregationReport(companyId, startMonth, endMonth, year) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    const url = this.config.getAggregationReportEndPoint + '/' + companyId + '/' + year + '/' + startMonth + '/' + endMonth

    const res = await this.http.get(url, options).toPromise()

    return Promise.resolve(res)
  }

  async downloadReport(fileName: string) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
      responseType: 'blob' as 'json',
    }

    const url = `${this.config.downloadReportEndPoint}/${fileName}?rndmzr=${uuidv4()}`

    const blob = await this.http.get<Blob>(url, options).toPromise()

    const blobUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.href = blobUrl
    link.download = fileName
    link.click()

    window.URL.revokeObjectURL(blobUrl)
  }

  async getTxtReport(companyId, startMonth, endMonth, year) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
    }

    const url = this.config.getTxtReportEndPoint
    console.log(url)

    const res = await this.http.post(url, {
      companyId, year, startMonth, endMonth
    }, options).toPromise()
    console.log(res)

    return Promise.resolve(res)
  }
  async downloadTxtReport(fileName: string) {
    const options = {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem(this.config.TokenLocalStorageName),
      }),
      responseType: 'blob' as 'json',
    }

    const types = ['DOC', 'PRM']
    for (const type of types) {
      const url = `${this.config.downloadTxtReportEndPoint}/${type}/${fileName}?rndmzr=${uuidv4()}`
      const blob = await this.http.get<Blob>(url, options).toPromise()

      const blobUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = blobUrl
      link.download = `MOVEIN.${type}`
      link.click()

      window.URL.revokeObjectURL(blobUrl)
    }
  }

}
