import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ConfigService } from '../../../Services/config/config.service'
import { AdminService } from '../../../Services/admin-service/admin.service'

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css'],
})
export class CreateAdminComponent implements OnInit {
  form: FormGroup
  formClasses: object
  loading: boolean
  succeeded = false
  message: string
  badMessage: boolean
  count: number
  maxAllowed: number
  limitExceeded: boolean

  constructor(private formBuilder: FormBuilder,
              private config: ConfigService,
              private adminService: AdminService) {
  }

  ngOnInit() {
    this.buildForm()
  }

  countAdmins(dontLimit: boolean = false) {
    this.adminService.countSubAdmins().then(res => {
      if (!res) return
      if (!res['success']) {
        alert(JSON.stringify(res))
        return
      }
      this.maxAllowed = res['maxAllowed']
      this.count = res['count']
      if (!dontLimit) this.limitExceeded = this.count >= this.maxAllowed
    })
  }

  buildForm() {
    // Check if not exceeded the limit
    this.countAdmins()
    if (this.limitExceeded) return

    this.form = this.formBuilder.group({
      name: ['', Validators.pattern(this.config.AdminNameRegExp)],
      username: ['', Validators.pattern(this.config.UserNameRegExp)],
      password: ['', Validators.pattern(this.config.PasswordRegExp)],
    })

    this.formClasses = {
      name: ['form-control'],
      username: ['form-control'],
      password: ['form-control'],
    }
  }

  validateFormControls() {
    for (const control in this.form.controls) {
      const validatorClass = this.form.controls[control].errors === null ? 'is-valid': 'is-invalid'

      let existingClasses = this.formClasses[control]
      existingClasses = existingClasses.filter(c => c !== 'is-valid' && c !== 'is-invalid')
      existingClasses.push(validatorClass)
      this.formClasses[control] = existingClasses
    }
  }
  submit() {
    this.validateFormControls()
    if (!this.form.valid) {
      this.badMessage = true
      this.message = 'הנתונים שהוכנסו אינם תקינים'
      return
    }

    this.adminService.createSubAdmin(
      this.form.controls['name'].value,
      this.form.controls['username'].value,
      this.form.controls['password'].value).then(res => {
      if (res['success']) {
        this.badMessage = false
        this.message = 'המנהל נוצר בהצלחה!!'
        this.succeeded = true
        // Check if not exceeded the limit
        this.countAdmins(true)
        return
      }
      this.badMessage = true
      if (res['message']) {
        this.message = res['message']
      } else {
        this.message = 'ישנו בעיה בשמירת הנתונים בשרת, נסו שוב מאוחר יותר'
        console.log(JSON.stringify(res))
      }

    })
  }

  makeNew() {
    this.buildForm()
    this.succeeded = false
    this.message = ''
  }
}
